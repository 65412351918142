import React from "react";
import { Route, Redirect } from "react-router-dom";

const User = ({routes}) => {

    return <div>
 
    {routes.map((routes, i) => {


            if (!routes.redirect) {
                return <Route key={routes.path} path={routes.path} render={() => <routes.component />}/>
            } else {

                return <Route exact key={routes.path} path={routes.path} render={() => <Redirect  from='/user' to={routes.redirect} />}/>
            }
        })}

</div>
};

export default User;