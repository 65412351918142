import React from "react";
import { Breadcrumb, Icon, Table } from 'antd';
import styles from './Schedule.css';
import { Link } from "react-router-dom";

function onChange(pagination, filters, sorter) {
    console.log('params', pagination, filters, sorter);
}
const columns = [{
    title: 'Name',
    dataIndex: 'name',
    filters: [{
        text: 'Joe',
        value: 'Joe',
    }, {
        text: 'Jim',
        value: 'Jim',
    }, {
        text: 'Submenu',
        value: 'Submenu',
        children: [{
            text: 'Green',
            value: 'Green',
        }, {
            text: 'Black',
            value: 'Black',
        }],
    }],
    // specify the condition of filtering result
    // here is that finding the name started with `value`
    onFilter: (value, record) => record.name.indexOf(value) === 0,
    sorter: (a, b) => a.name.length - b.name.length,
}, {
    title: 'Age',
    dataIndex: 'age',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.age - b.age,
}, {
    title: 'Address',
    dataIndex: 'address',
    filters: [{
        text: 'London',
        value: 'London',
    }, {
        text: 'New York',
        value: 'New York',
    }],
    filterMultiple: false,
    onFilter: (value, record) => record.address.indexOf(value) === 0,
    sorter: (a, b) => a.address.length - b.address.length,
}];

const data = [{
    key: '1',
    name: 'John Brown',
    age: 32,
    address: 'New York No. 1 Lake Park',
}, {
    key: '2',
    name: 'Jim Green',
    age: 42,
    address: 'London No. 1 Lake Park',
}, {
    key: '3',
    name: 'Joe Black',
    age: 32,
    address: 'Sidney No. 1 Lake Park',
}, {
    key: '4',
    name: 'Jim Red',
    age: 32,
    address: 'London No. 2 Lake Park',
}];

class SelectResult extends React.Component {
    constructor(props) {
        console.log(props)
        super(props)
        this.state = {};
    }

    render() {
        return (
            <div>
        <div className={styles.breadcrumb}>
            <Breadcrumb>
    <Breadcrumb.Item>
      <Icon type="laptop" />
    </Breadcrumb.Item>
    <Breadcrumb.Item>
    <Link to='/schedule'>
      排课管理</Link>
    </Breadcrumb.Item>
    <Breadcrumb.Item>
      选课结果
    </Breadcrumb.Item>
  </Breadcrumb></div>
     <div className={styles.strategyContent}>
     <div className={styles.strategyTable}>
 <Table columns={columns} dataSource={data} onChange={onChange} />

            </div>
           
          
           </div>
         
           
            </div>
        );
    }
}

export default SelectResult;