import Basic from '../components/layout/Basic';
import User from '../components/layout/User';
import Login from '../pages/user/Login';
import Register from '../pages/user/Register';
import Index from '../pages/dashboard/Index';
import HospitalList from '../pages/hospital/hospital/list';
import CreateHospital from '../pages/hospital/hospital/create';
import EditHospital from '../pages/hospital/hospital/edit';
import HospitalDepartment from '../pages/hospital/department/department';
import CreateHospitalDepartment from '../pages/hospital/department/create';
import EditHospitalDepartment from '../pages/hospital/department/edit';
import HospitalDoctor from '../pages/hospital/doctor/doctor';
import CreateHospitalDoctor from '../pages/hospital/doctor/create';
import EditHospitalDoctor from '../pages/hospital/doctor/edit';

//import Index2 from '../pages/other/index';excel转json
import Schedule from '../pages/schedule/Index';
import Create from '../pages/schedule/Create';
import Setting_noSelect from '../pages/schedule/Setting_noSelect';
import Setting_select from '../pages/schedule/Setting_select';
import SelectResult from '../pages/schedule/SelectResult';
import PrivilegeFunc from '../pages/privilege/func/func';
import PrivilegeRole from '../pages/privilege/role/role';
import PrivilegeUser from '../pages/privilege/user/user';
import CreatePrivilege from '../pages/privilege/func/create';
import EditPrivilege from '../pages/privilege/func/edit';
import CreatePrivilegeRole from '../pages/privilege/role/create';
import EditPrivilegeRole from '../pages/privilege/role/edit';
import PrivilegeMerchant from '../pages/privilege/merchant/merchant';
import CreatePrivilegeMerchant from '../pages/privilege/merchant/create';
import EditPrivilegeMerchant from '../pages/privilege/merchant/edit';
import CreatePrivilegeUser from '../pages/privilege/user/create';
import EditPrivilegeUser from '../pages/privilege/user/edit';


export default [{
    path: "/user",
    component: User,
    routes: [{
        path: '/user',
        redirect: '/user/login'
    }, {
        path: '/user/login',
        component: Login
    }, {
        path: '/user/register',
        component: Register
    }],
}, {
    path: "/",
    component: Basic,
    Routes: ['src/pages/Authorized'],
    authority: ['admin', 'user'],
    routes: [{
        path: "/",
        redirect: '/dashboard'
    }, {
        path: "/dashboard",
        name: 'dashboard',
        component: Index,
        icon: 'dashboard',
        menu : '/dashboard',
    }, {
        path: "/hospital",
        name: '医院管理',
        icon: 'form',
        menu : '/hospital',
        routes: [{
            path: '/hospital/list',
            name: '医院列表',
            component: HospitalList,
            menu : '/hospital/list'
        }, {
            path: '/hospital/department',
            component: HospitalDepartment,
            name: '科室管理',
            menu : '/hospital/department'
        }, {
            path: '/hospital/doctor',
            name: '职工管理',
            component: HospitalDoctor,
            menu : '/hospital/doctor'
        },],
    }, {
        path: "/privilege",
        name: '系统管理',
        icon: 'usergroup-add',
        menu : '/privilege',
        routes: [{
            path: '/privilege/func',
            name: '功能模块',
            component: PrivilegeFunc,
            menu : '/privilege/func'
        }, {
            path: '/privilege/role',
            name: '角色管理',
            component: PrivilegeRole,
            menu : '/privilege/role',
        },{
            path: '/privilege/merchant',
            name: '商户管理',
            component: PrivilegeMerchant,
            menu : '/privilege/merchant',
        }, {
            path: '/privilege/user',
            component: PrivilegeUser,
            menu : '/privilege/user',
            name: '管理员',
        }],
    }/*, {
        path: "/schedule",
        name: '订单管理',
        component: Schedule,
        icon: 'laptop',
    }*/, {
        path: "/schedule/create",
        name: '新建排课',
        component: Create,
        hide: 'true'
    }, {
        path: "/schedule/setting_noSelect",
        name: '排课策略',
        component: Setting_noSelect,
        hide: 'true'
    }, {
        path: "/schedule/setting_select",
        name: '排课策略',
        component: Setting_select,
        hide: 'true'
    }, {
        path: "/schedule/selectResult",
        name: '选课结果',
        component: SelectResult,
        hide: 'true'
    }, {
        path: "/banji",
        name: '文章管理',
        component: Index,
        icon: 'home'
    }
, {
        path: "/hospital/create",
        name: '添加医院',
        component: CreateHospital,
        hide: 'true',
         menu : '/hospital/list'
    }, {
        path: "/hospital/edit",
        name: '编辑医院',
        component: EditHospital,
        hide: 'true',
        menu : '/hospital/list'
    }
    , {
        path: "/privilege/func/create",
        name: '添加功能',
        component: CreatePrivilege,
        hide: 'true',
        menu : '/privilege/func'
    },{
        path: "/privilege/func/edit",
        name: '编辑功能',
        component: EditPrivilege,
        hide: 'true',
        menu : '/privilege/func'
    }, {
        path: "/privilege/role/create",
        name: '添加角色',
        component: CreatePrivilegeRole,
        hide: 'true',
        menu : '/privilege/role'
    },{
        path: "/privilege/role/edit",
        name: '编辑角色',
        component: EditPrivilegeRole,
        hide: 'true',
        menu : '/privilege/role'
    },{
        path: "/privilege/merchant/create",
        name: '添加商户',
        component: CreatePrivilegeMerchant,
        hide: 'true',
        menu : '/privilege/merchant'
    },{
        path: "/privilege/merchant/edit",
        name: '编辑商户',
        component: EditPrivilegeMerchant,
        hide: 'true',
        menu : '/privilege/merchant'
    },{
        path: "/privilege/user/create",
        name: '添加管理员',
        component: CreatePrivilegeUser,
        hide: 'true',
        menu : '/privilege/user'
    },{
        path: "/privilege/user/edit",
        name: '编辑管理员',
        component: EditPrivilegeUser,
        hide: 'true',
        menu : '/privilege/user'
    },{
        path: "/hospital/department/create",
        name: '添加科室',
        component: CreateHospitalDepartment,
        hide: 'true',
        menu : '/hospital/department'
    },{
        path: "/hospital/department/edit",
        name: '编辑管理员',
        component: EditHospitalDepartment,
        hide: 'true',
        menu : '/hospital/department'
    },{
        path: "/hospital/doctor/create",
        name: '添加职工',
        component: CreateHospitalDoctor,
        hide: 'true',
        menu : '/hospital/doctor'
    },{
        path: "/hospital/doctor/edit",
        name: '编辑职工',
        component: EditHospitalDoctor,
        hide: 'true',
        menu : '/hospital/doctor'
    }

    ]
}];