import React from "react";
import { Table,  Tag, Progress, Button, Breadcrumb, Icon, Tooltip } from 'antd';
import styles from './Schedule.css';
import { Link } from "react-router-dom";
const columns = [{
    title: '课程',
    dataIndex: 'course',
    key: 'course',
    render: text => <Button>{text}</Button>,
}, {
    title: '学生',
    dataIndex: 'students',
    width: '15%',
    key: 'students',
}, {
    title: '创建时间',
    dataIndex: 'time',
    width: '15%',
    key: 'time',
}, {
    title: '排课策略',
    key: 'tags',

    dataIndex: 'tags',
    render: tags => {
        return <span className={styles.tagBlock}>
      {tags.map(tag => <Tag className={styles.tag} color="orange" key={tag}>{tag}</Tag>)}
    </span>
    },
}, {
    title: '选课进度',
    key: 'progress',
    width: '18%',
    dataIndex: 'progress',
    render: progress => {
        return <span>
        <Tooltip title="800/1000">
<Progress  type='circle' percent={progress} width={60}/>
</Tooltip>
    </span>

    },
}, {
    title: '操作',
    key: 'action',
    width: '12%',
    render: (text, record) => (

        <span>
      <Button>查看详情</Button>
    </span>
    ),
}];
const data = [{
    key: '1',
    course: '高一',
    students: '2020届',
    time: '2018-11-14 16:00',
    progress: 10,
    tags: ['nice', 'developer'],
}, {
    key: '2',
    course: '高二',
    students: '2021届',
    time: '2018-11-14 16:00',
    progress: 20,
    tags: ['loser'],
}, {
    key: '3',
    course: '高三',
    students: '2022届',
    time: '2018-11-14 16:00',
    progress: 100,
    tags: ['cool', 'teacher'],
}];
class Schedule extends React.Component {
    render() {
        return (
            <div>
        <div className={styles.breadcrumb}>
            <Breadcrumb>
    <Breadcrumb.Item>
      <Icon type="laptop" />
    </Breadcrumb.Item>
    <Breadcrumb.Item>
      排课管理
    </Breadcrumb.Item>
  </Breadcrumb></div>
  <div className={styles.border}>
             <div className={styles.title}><span>新建排课任务，并根据提示配置好各项数据后，系统将自动给出排课结果。</span><Link to="/schedule/create"><Button type="primary" size = 'large'>新建排课</Button></Link>
            </div>
           <div className={styles.table}>
            <Table columns={columns} dataSource={data} pagination={false}/></div>
            </div></div>
        );
    }
}

export default Schedule;