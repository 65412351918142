import React from "react";
import {  Button, message,Form, Input, Row, Col, TreeSelect,Select  } from 'antd';
import http from '../../../server';
import { withRouter } from "react-router";
import CONFIG from '../../../config';
const { TextArea } = Input;
const FormItem = Form.Item;
const Option = Select.Option;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 7 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
};

var selectData=[];

class CreateHospital extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username : '',
      password : '',
      repassword : '',
      loading : false,
      value: undefined,
      name : '',
      icon : '',
      status : 1,
      business_introduction : '',
      sort : 99,
      treeSelectData : [],
      roleData : [],
      role_id : undefined,
    };
  }
  componentDidMount(){    
    this.fetch({mer_id : CONFIG.S.get('mer_id')||''});
  }

createSelect(pid){
  let temp = [];
  for(let i = 0;i<selectData.length;i++){
    if(selectData[i].p_id===pid){
      temp.push({
        title : selectData[i].name,
        sort : selectData[i].sort,
        value : selectData[i].id,
        key : selectData[i].p_id+'-'+selectData[i].id,
        children : [],
        ...selectData[i]
      });
    }
  }
  if(temp.length){
    temp = temp.sort(function(a,b){
      return a.sort > b.sort ? 1 : -1
    });
    for(let j = 0;j<temp.length;j++){
      temp[j].children=this.createSelect(temp[j].id);
    }
  }
  return temp;
}
//获取商户
async fetch(params={}){
    let _this = this;
    await http.post('/Backend/merchant/find',{...params}).then(function(data){
      data = data.data;
      selectData = data.rows||[];
      _this.setState({
        treeSelectData : _this.createSelect(CONFIG.S.get('mer_pid')||"0")
      })
    }).then(_this.fetchRole.bind(_this))
  }
//获取角色

async fetchRole(params = {}){
    let _this = this;
    await http.post('/Backend/sysRole/find',{...params}).then(function(data){
      data = data.data;
      _this.setState({
        roleData: data.rows
      });
    })
  }

  onChange = (value) => {    
    this.setState({ value});
  }
  onChangeRole = (v) => {
    this.setState({ role_id : v});
  }
  onChangeInput = prop => e => {    
      this.setState({
          [prop]: e.target.value.trim()
      });
  }
  //回车事件
  KeyUpHandle(e){
      if (e.keyCode !== 13) {
          return;
      }
      this.submit();
  }
  //提交
  submit(){
    let _this = this;    
    if(!this.state.name || this.state.name.length < 2 || this.state.name.length > 40){
            message.error('请输入用户姓名');
            return;
        }
        if(!this.state.value){
            message.error('请选择所属商户');
            return;
        }
        if(this.state.password !== this.state.repassword){
          message.error('两次密码输入不一样，请重新输入');
          return;
        }
        // this.setState({
        //     loading: true
        // });
        http.post('/Backend/sysUser/create',{
            real_name : this.state.name,
            username : this.state.username,
            password : this.state.password,
            status : 1,
            desc : this.state.desc,
            mer_id : this.state.value.value,
            role_id : this.state.role_id,
            group_id : '0'

          }).then((data) =>{
            data = data.data;
            if(data.code === 0){
              message.success(data.msg);
              _this.props.history.go(-1);
            }else{
              message.error(data.msg);
              _this.setState({
                  loading: false
              });
            }
          });
  }
  render() {
    const {loading}=this.state;
    return (
      <div>
        <div className="steps-content">
          <div style={{padding:'24px 30px'}}>
      <Form>
    <FormItem
      {...formItemLayout}
      label="登录用户名"
      validateStatus=""
      help=""
    >
      <Input placeholder="请输入登录用户名,2-40个字" value={this.state.username} id="username" 
            onKeyUp={this.KeyUpHandle.bind(this)} maxLength={40}
            onChange={this.onChangeInput('username')}/>
    </FormItem>
    <FormItem
      {...formItemLayout}
      label="登录密码"
      validateStatus=""
      help=""
    >
      <Input type="password" placeholder="请输入登录密码(默认123456)" value={this.state.password} id="password" 
            onKeyUp={this.KeyUpHandle.bind(this)} maxLength={40}
            onChange={this.onChangeInput('password')}/>
    </FormItem>
    <FormItem
      {...formItemLayout}
      label="重复登录密码"
      validateStatus=""
      help=""
    >
      <Input type="password" placeholder="请再次输入登录密码" value={this.state.repassword} id="repassword" 
            onKeyUp={this.KeyUpHandle.bind(this)} maxLength={40}
            onChange={this.onChangeInput('repassword')}/>
    </FormItem>
    <FormItem
      {...formItemLayout}
      label="所属商户"
      hasFeedback
      validateStatus=""
      help=""
    >
      <TreeSelect
        style={{ width: '100%'}}
        value={this.state.value}
        treeData={this.state.treeSelectData}
        placeholder="请选择所属商户"
        treeDefaultExpandAll
        labelInValue
        onChange={this.onChange}
      />
    </FormItem>
    <FormItem
      {...formItemLayout}
      label="分配角色"
      hasFeedback
      validateStatus=""
      help=""
    >
      <Select 
        style={{ width: '100%'}}
        value={this.state.role_id}
        placeholder="请分配角色"
        onChange={this.onChangeRole}
      >
      {this.state.roleData.map((v)=>(
        <Option value={v.id} key={v.id}>{v.name}</Option>
      ))}
      </Select>
    </FormItem>
    <FormItem
      {...formItemLayout}
      label="用户姓名"
      validateStatus=""
      help=""
    >
      <Input placeholder="请输入用户姓名,2-40个字" value={this.state.name} id="name" 
            onKeyUp={this.KeyUpHandle.bind(this)} maxLength={40}
            onChange={this.onChangeInput('name')}/>
    </FormItem>
    <FormItem
      {...formItemLayout}
      label="管理员简介"
      validateStatus=""
      help=""
    >
      <TextArea placeholder="备注管理员(选填)1000字以内" autosize value={this.state.desc} id="desc" 
            onKeyUp={this.KeyUpHandle.bind(this)} maxLength={100}
            onChange={this.onChangeInput('desc')}/>
    </FormItem>
  </Form>

    </div>
        </div>
        <div className="steps-action" style={{textAlign:'left',paddingBottom:'50px'}}>
        <Row gutter={48}>
        <Col className="gutter-row" span={7}></Col>
          <Col className="gutter-row" span={10}>
          <Button type="primary" loading={loading} onClick={this.submit.bind(this)}>{loading ? '提交中' : '提交'}</Button>          
          </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withRouter(CreateHospital)