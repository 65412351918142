import React from "react";
import {  Button, message,Form, Input, Row, Col,Radio,Upload,Icon,Select  } from 'antd';
import http from '../../../server';
import { withRouter } from "react-router";
import CONFIG from '../../../config';
const { TextArea } = Input;
const FormItem = Form.Item;
const Option = Select.Option;
const RadioGroup = Radio.Group;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 7 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
};
let uploadOpt = {
  accept : 'image/gif, image/jpeg, image/jpg, image/png, image/bmp',
  action: CONFIG.url.lc_url+'Backend/backend/upload',
  listType: 'picture',
  defaultFileList: [],
  disabled : false,//是否禁用
  multiple : false,//多选文件
  withCredentials : true,
  className: 'upload-list-inline'
};
var selectData=[];

class CreateDepartment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {      
      loading : false,
      has_da_keshi : '1',
      h_id: props.location && props.location.state && props.location.state.h_id && props.location.state.h_id!== '0' ? props.location.state.h_id : undefined,
      deptSelectData : [],
      dep_name : '',
      dep_class : '1',
      p_id : '',
      p_dep : undefined,
      is_catagory : '0',
      is_online : '0',
      dep_logo : '',
      dep_intr : '',
      status : 1,
      location : '',
      phone : '',
      treeSelectData : [],
      has_inhosiptal : '1',
      sort : 99,
      banner : [],
      uploadbanner : {
        ...uploadOpt,
        multiple : true,//多选文件
        onChange : this.changeUpload.bind(this,'banner',5),
        onRemove : this.removeUpload.bind(this,'banner',5)
      },
    };
  }
  componentDidMount(){    
    this.fetch({mer_id : CONFIG.S.get('mer_id')||''});
  }
  componentWillUnmount = () => {
    this.setState = (state,callback)=>{
      return;
    };
  }
  //上传图片
  changeUpload = (type,len,e) =>{
    if(e.file.status === 'done'){
      //上完完成
      if(e.file.response.code === 0){
        //上传成功
        this.setState({
          [type] : e.fileList,//e.file.response.data.save_path,
          ['upload'+type] : {
            ...this.state['upload'+type],disabled:(e.fileList.length >= len ? true : false),
            defaultFileList : e.fileList
          }
        });
      }
    }
  }
  //删除图片
  removeUpload = (type,len,e) =>{
    if(e.status === 'removed'){
      let arr = this.state[type].filter(o => o.uid !== e.uid);
      this.setState({
        [type] : arr,//e.file.response.data.save_path,
        ['upload'+type] : {...this.state['upload'+type],disabled:(arr.length >= len ? true : false),defaultFileList:arr}
      })
    }    
  }
  getUploadPath(arr){
    if(!arr.length)return '';
    for(let i = 0;i<arr.length;i++){arr[i] = arr[i].response.data.save_path;}
    return arr.join(',');
  }
//获取医院列表
async fetch(params={}){
    let _this = this;
    await http.post('/Backend/hospital/find',params).then(function(data){
      data = data.data;
      selectData = data.rows||[];
      let o = {};
      o.treeSelectData = selectData;
      o.has_da_keshi = _this.state.h_id ? selectData.filter(v=>v.id === _this.state.h_id)[0].id : '1';
      _this.setState(o)
    }).then(_this.fetchDepart.bind(_this,{}))
  }
//获取科室

async fetchDepart(params = {}){
    let _this = this;
    if(!_this.state.h_id && !params.h_id)return;
    if(_this.state.has_da_keshi === '1'){
      return;
    }
    await http.post('/Backend/depart/find',{p_id : '0',h_id : _this.state.h_id||params.h_id}).then(function(data){
      data = data.data;
      let rows = [{id : '0',dep_name:'一级科室'},...data.rows]
      _this.setState({
        deptSelectData: rows
      });
    })
  }
//选择医院
  onChange = (h_id) => {
    let _this = this;
    for(let i = 0;i<selectData.length;i++){
      if(selectData[i].id === h_id){
        this.setState({ h_id:h_id,has_da_keshi:selectData[i].has_da_keshi},()=>_this.fetchDepart({ h_id:h_id}));
        return;
      }
    }    
  }
  //上级科室
  onChangePid = (p_id) => {
    this.setState({
      p_id : p_id,
      p_dep : this.state.deptSelectData.filter(v => v.id === p_id)[0].dep_name
    });
  }
  onChangeInput = prop => e => {    
      this.setState({
          [prop]: e.target.value.trim()
      });
  }
  onChangeRadio = prop => e => {
      this.setState({
          [prop]: e.target.value
      });
  }
  //回车事件
  KeyUpHandle(e){
      if (e.keyCode !== 13) {
          return;
      }
      this.submit();
  }
  //提交
  submit(){
    let _this = this;
    let o = {
      dep_intr : '',
      dep_logo : '',
      dep_name : '',
      h_id : '',
      has_da_keshi : '',
      has_inhosiptal : '',
      is_catagory : '',
      is_online : '',
      location : '',
      p_id : '',
      phone : '',
      sort : '',
    };
    for(let k in o){o[k] = _this.state[k]}
    o.dep_logo = _this.getUploadPath(_this.state.banner);    
    if(!this.state.dep_name || this.state.dep_name.length < 2 || this.state.dep_name.length > 40){
            message.error('请输入科室名称');
            return;
        }
        if(!o.dep_logo){
            message.error('请上传科室banner图片');
            return;
        }
        if(!this.state.h_id || this.state.h_id === '0'){
          message.error('请选择所属医院');
          return;
        }
        if(!this.state.dep_intr || this.state.dep_intr.length < 2){
          message.error('请输入科室简介');
          return;
        }
        // this.setState({
        //     loading: true
        // });
        http.post('/Backend/depart/create',o).then((data) =>{
            data = data.data;
            if(data.code === 0){
              message.success(data.msg);
              _this.props.history.go(-1);
            }else{
              message.error(data.msg);
              _this.setState({
                  loading: false
              });
            }
          });
  }
  render() {
    const {loading}=this.state;
    return (
      <div>
        <div className="steps-content">
          <div style={{padding:'24px 30px'}}>
      <Form>
    <FormItem
      {...formItemLayout}
      label="科室名称"
      validateStatus=""
      help=""
    >
      <Input placeholder="请输入科室名称" value={this.state.dep_name} id="dep_name" 
            onKeyUp={this.KeyUpHandle.bind(this)} maxLength={40}
            onChange={this.onChangeInput('dep_name')}/>
    </FormItem>    
    <FormItem
      {...formItemLayout}
      label="所属医院"
      hasFeedback
      validateStatus=""
      help=""
    >
      <Select
        style={{ width: '100%'}}
        value={this.state.h_id}
        placeholder="请选择所属医院"
        onChange={this.onChange}>
        {
          this.state.treeSelectData.length && this.state.treeSelectData.map((k,v)=>(
            <Option value={k.id} key={k.id}>{k.name}</Option>
            ))
        }
      </Select>
    </FormItem>
    <FormItem
        {...formItemLayout}
        label="科室分类"
        hasFeedback
        validateStatus=""
      >
        <RadioGroup onChange={this.onChangeRadio('is_catagory')} value={this.state.is_catagory}>          
              <Radio value='0'>普通科室</Radio><Radio value='1'>专家科室</Radio>
        </RadioGroup>
      </FormItem>

      <FormItem
        {...formItemLayout}
        label="科室性质"
        hasFeedback
        validateStatus=""
      >
        <RadioGroup onChange={this.onChangeRadio('dep_class')} value={this.state.dep_class}>          
              <Radio value='1'>医疗科室</Radio>
              <Radio value='2'>医技科室</Radio>
              <Radio value='3'>手术室</Radio>
              <Radio value='4'>病案室</Radio>
              <Radio value='5'>行政科室</Radio>
        </RadioGroup>
      </FormItem>

    <FormItem
        {...formItemLayout}
        label="是否支持远程问诊"
        hasFeedback
        validateStatus=""
      >
        <RadioGroup onChange={this.onChangeRadio('is_online')} value={this.state.is_online}>          
              <Radio value='0'>不支持</Radio><Radio value='1'>支持</Radio>
        </RadioGroup>
      </FormItem>


    <FormItem
        {...formItemLayout}
        label="是否有住院部"
        hasFeedback
        validateStatus=""
      >
        <RadioGroup onChange={this.onChangeRadio('has_inhosiptal')} value={this.state.has_inhosiptal}>          
              <Radio value='1'>有</Radio><Radio value='2'>无</Radio>
        </RadioGroup>
      </FormItem>


    <FormItem
      {...formItemLayout}
      label="科室位置"
      validateStatus=""
      help=""
    >
      <Input placeholder="如:外科楼3层305室" value={this.state.location} id="location" 
            onKeyUp={this.KeyUpHandle.bind(this)} maxLength={40}
            onChange={this.onChangeInput('location')}/>
    </FormItem>

    <FormItem
      {...formItemLayout}
      label="上级科室"
      hasFeedback
      validateStatus=""
      help=""
      style={{display:(this.state.has_da_keshi === '1'?'none':'block')}}
    >
      <Select
        style={{ width: '100%'}}
        value={this.state.p_dep}
        placeholder="请选择上级科室"
        onChange={this.onChangePid.bind(this)}>
        {
          this.state.deptSelectData.length && this.state.deptSelectData.map((k,v)=>(
            <Option value={k.id} key={k.id}>{k.dep_name}</Option>
            ))
        }
      </Select>
    </FormItem>

    <FormItem
      {...formItemLayout}
      label="排序"
      validateStatus=""
      help=""
    >
      <Input placeholder="排列顺序" value={this.state.sort} id="sort" 
            onKeyUp={this.KeyUpHandle.bind(this)} maxLength={40}
            onChange={this.onChangeInput('sort')}/>
    </FormItem>
    <FormItem
        {...formItemLayout}
        label="科室顶部banner图"
        validateStatus=""
      >
        <Upload {...this.state.uploadbanner} key="uploaddeptbanner1">
          <Button>
            <Icon type="upload" key="1"/> 750*290等比率
          </Button>
        </Upload>
      </FormItem>

    <FormItem
      {...formItemLayout}
      label="科室电话"
      validateStatus=""
      help=""
    >
      <Input placeholder="科室联系电话" value={this.state.phone} id="phone" 
            onKeyUp={this.KeyUpHandle.bind(this)} maxLength={12}
            onChange={this.onChangeInput('phone')}/>
    </FormItem>
    <FormItem
      {...formItemLayout}
      label="科室简介"
      validateStatus=""
      help=""
    >
      <TextArea placeholder="科室简介,5000字以内" autosize value={this.state.dep_intr} id="dep_intr" 
            onKeyUp={this.KeyUpHandle.bind(this)} maxLength={5000}
            onChange={this.onChangeInput('dep_intr')}/>
    </FormItem>

  </Form>

    </div>
        </div>
        <div className="steps-action" style={{textAlign:'left',paddingBottom:'50px'}}>
        <Row gutter={48}>
        <Col className="gutter-row" span={7}></Col>
          <Col className="gutter-row" span={10}>
          <Button type="primary" loading={loading} onClick={this.submit.bind(this)}>{loading ? '提交中' : '提交'}</Button>          
          </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withRouter(CreateDepartment)