import React from "react";
import { Table, Divider,Popconfirm,Button,Icon  } from 'antd';
import LayoutTitle from '../../../components/layout/title';
import CONFIG from '../../../config';
import reqwest from 'reqwest';
import { withRouter } from "react-router";

class Privilege extends React.Component {
	state = {
    data: [],
    loading: false,
    columns : [{
        title: '名称',
        dataIndex: 'name',
        render: (name,icon) => (<span><Icon type={icon} />{name}</span>)
      }, {
        title: 'url地址',
        dataIndex: 'url',
        width: '20%',
      }, {
        title: '排序',
        dataIndex: 'sort',
        width: '20%',
      }, {
        title: '操作',
        key: 'action',
        render: (text, record) => (
          <span>
            <Button type="primary" onClick={this.edit.bind(this,text,record)} style={{height:'auto'}}>编辑</Button>
            <Divider type="vertical" />
            <Popconfirm placement="topRight" title={'确认删除该功能吗?'+(text.children&&text.children.length ? '相应的子功能也会删除!':'')} onConfirm={this.delete.bind(this,text,record)} okText="确认" cancelText="取消">
              <Button type="danger" style={{height:'auto'}}>删除</Button>
            </Popconfirm>
          </span>
        ),
        width: '20%',
      }] 
  };
  edit(text,record){
    this.props.history.push('/privilege/func/edit',text);
  }
  delete(text,record){
console.log(text);
  }
  handleTableChange = (pagination, filters, sorter) => {
    
  }

  fetch = (params = {}) => {
    this.setState({ loading: true });
    let _this = this;
    reqwest({
          url: CONFIG.url.lc_url+'Backend/sysFunc/find',
          method: 'post',
          contentType: 'application/x-www-form-urlencoded',
          crossOrigin: true,
          withCredentials: true,
          data: {
            ...params,
          },
          type: 'json',
        }).then((data) => {
          var selectData=data.rows;
          var createSelect = function(pid){
            let temp = [];
            for(let i = 0;i<selectData.length;i++){
              if(selectData[i].pid===pid){
                temp.push({                  
                  key : selectData[i].pid+'-'+selectData[i].id,
                  children : [],
                  ...selectData[i]
                });
              }
            }
            if(temp.length){
              temp = temp.sort(function(a,b){
                return a.sort > b.sort ? 1 : -1
              });
              for(let j = 0;j<temp.length;j++){
                temp[j].children=createSelect(temp[j].id);
                temp[j].children = temp[j].children.length ? temp[j].children : undefined;
              }
            }
            return temp;
          }
            _this.setState({
              loading: false,
              data: createSelect("0")
            });
        });




  }
  createHospital(){
    this.props.history.push('/privilege/func/create')
  }
  componentDidMount() {
    this.fetch();
  }
	render(){
		const createHospital = this.createHospital.bind(this)
	return <div>
			<LayoutTitle name="功能列表" opt={{btn : [{click:createHospital,text:'添加功能'}]}} />
			<div style={{paddingTop:'8px'}}>
				
				<div style={{paddingTop:'8px'}}>
					{this.state.data.length ? <Table
				        columns={this.state.columns}
				        rowKey={record => record.id}
				        dataSource={this.state.data}
				        loading={this.state.loading}
				        onChange={this.handleTableChange}
                defaultExpandAllRows={true}
                pagination={false}
				      />
          : '暂无数据'}
				</div>
			</div>
		</div>
	}
}

export default withRouter(Privilege);