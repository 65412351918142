import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import routes from './router/router.config';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { theme } from './styles/styles'

require('./App.css');

const RouteWithSubRoutes = route => (
    <Route
    path={route.path}
    render={props => (
        // pass the sub-routes down to keep nesting
        <route.component {...props} routes={route.routes} />
    )
    }
    />
);
class App extends Component {
    render() {
        return (
            <MuiThemeProvider theme={theme}>
            <Router>
            <Switch>
      {routes.map((route, i) => <RouteWithSubRoutes key={i} {...route} />)}
         </Switch>
      </Router>
       </MuiThemeProvider>
        );
    }
}

export default App;