import CONFIG from './config';
let urlToArr = function(str,type,len){
    let arr = [];
    if(str){
      const s= str.split(',');
      for(let i = 0;i<s.length;i++){
        if(s[i]){
          arr.push({
            uid : type+''+i,
            name : type+''+i,
            status : 'done',
            response:{data:{save_path:s[i]}},
            url : CONFIG.url.lr_url+s[i]
          });
        }
      }
    }
    return {defaultFileList : arr,disabled :arr.length>=len?false : true};
};
export default {
  urlToArr
}