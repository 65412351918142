import React from "react";
import { Steps, Button, message,Form, Input, Row, Col, Select, Radio,Upload,Icon,Card,TreeSelect  } from 'antd';
import http from '../../../server';
import { withRouter } from "react-router";
import CONFIG from '../../../config';
import util from '../../../util';

const RadioGroup = Radio.Group;
const Step = Steps.Step;
const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 7 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
};
let uploadOpt = {
  accept : 'image/gif, image/jpeg, image/jpg, image/png, image/bmp',
  action: CONFIG.url.lc_url+'Backend/backend/upload',
  listType: 'picture',
  defaultFileList: [],
  disabled : false,//是否禁用
  multiple : false,//多选文件
  withCredentials : true,
  className: 'upload-list-inline'
};
var selectData=[];

class CreateHospital extends React.Component {
  constructor(props) {
    super(props);
    let o = {...props.location.state};
    if(!o){
      props.history.go(-1);
    }
    this.state = {
      ...o,
      current: 0,
      hospitalDengji : [],//等级
      type : o.type||undefined,
      hospitalLeixin : [],//类型
      qualified : o.qualified||undefined,
      hospitalXingzhi : [],//经营性质
      qualified_code : o.qualified_code||undefined,
      province : [],
      province_id : o.province || undefined,
      city : [],
      city_id : o.city || undefined,
      district : [],
      district_id:o.area||undefined,
      treeSelectData : [],
      treeSelectValue: o.mer_id?{label:o.mer_name,value:o.mer_id} : undefined,//服务商
      telphone : o.telphone||'',
      name : o.name||'',
      contact : o.contact || '',
      phone : o.phone || '',
      email : o.email || '',
      address : o.address || '',
      logo : util.urlToArr(o.logo,'logo',1).defaultFileList || [],
      banner : util.urlToArr(o.banner,'banner',5).defaultFileList || [],
      desc : o.desc || '',
      intr : o.intr || '',
      ali_pid : o.ali_pid || '',
      ali_appid : o.ali_appid || '',
      ali_public_key : o.ali_public_key || '',
      ali_app_private_key : o.ali_app_private_key || '',
      ali_merchant_public_key : o.ali_merchant_public_key || '',
      ali_qr : util.urlToArr(o.ali_qr,'ali_qr',1).defaultFileList || [],
      wx_appid : o.wx_appid || '',
      wx_key : o.wx_key || '',
      wx_appsecret : o.wx_appsecret || '',
      wx_token : o.wx_token || '',
      wx_mch_id : o.wx_mch_id || '',
      wx_sub_appid : o.wx_sub_appid || '',
      wx_sub_appsecret : o.wx_sub_appsecret || '',
      wx_sub_mch_id : o.wx_sub_mch_id || '',
      wx_qr : util.urlToArr(o.wx_qr,'wx_qr',1).defaultFileList || [],
      wx_cert : o.wx_cert || '',
      wx_key_cert : o.wx_key_cert || '',
      his_interface : o.his_interface || '',
      his_private_key : o.his_private_key || '',
      his_public_key : o.his_public_key || '',
      zhuyuan_yujiao_jinge : o.zhuyuan_yujiao_jinge || '',
      medical_deposit_price : o.medical_deposit_price || '',
      pay_all : o.pay_all || '',
      pay_method : o.pay_method || '',
      yuyue_method : o.yuyue_method || '',
      yuyue_quxiao_time : o.yuyue_quxiao_time || '',
      yuyue_quxiao : o.yuyue_quxiao || '',
      lock_db : o.lock_db || '',
      can_yuyue_day : o.can_yuyue_day || '',
      has_da_keshi : o.has_da_keshi || '',
      his_trans_type : o.his_trans_type || '',
      uploadlogo : {
        ...uploadOpt,
        onChange : this.changeUpload.bind(this,'logo',1),
        onRemove : this.removeUpload.bind(this,'logo',1),
        ...util.urlToArr(o.logo,'logo',3)

      },
      uploadbanner : {
        ...uploadOpt,
        multiple : true,//多选文件
        onChange : this.changeUpload.bind(this,'banner',5),
        onRemove : this.removeUpload.bind(this,'banner',5),
        ...util.urlToArr(o.banner,'banner',5)
      },
      uploadali_qr : {
        ...uploadOpt,
        onChange : this.changeUpload.bind(this,'ali_qr',1),
        onRemove : this.removeUpload.bind(this,'ali_qr',1),
        ...util.urlToArr(o.ali_qr,'ali_qr',3)
      },
      uploadwx_qr : {
        ...uploadOpt,
        onChange : this.changeUpload.bind(this,'wx_qr',1),
        onRemove : this.removeUpload.bind(this,'wx_qr',1),
        ...util.urlToArr(o.wx_qr,'wx_qr',3)
      },
    }
  }
  //上传图片
  changeUpload = (type,len,e) =>{
    if(e.file.status === 'done'){
      //上完完成
      if(e.file.response.code === 0){
        //上传成功
        this.setState({
          [type] : e.fileList,//e.file.response.data.save_path,
          ['upload'+type] : {
            ...this.state['upload'+type],disabled:(e.fileList.length >= len ? true : false),
            defaultFileList : e.fileList
          }
        });
      }
    }
  }
  //删除图片
  removeUpload = (type,len,e) =>{
    if(e.status === 'removed'){
      let arr = this.state[type].filter(o => o.uid !== e.uid);
      this.setState({
        [type] : arr,//e.file.response.data.save_path,
        ['upload'+type] : {...this.state['upload'+type],disabled:(arr.length >= len ? true : false),defaultFileList:arr}
      })
    }    
  }
  getUploadPath(arr){
    if(!arr.length)return '';
    for(let i = 0;i<arr.length;i++){arr[i] = arr[i].response.data.save_path;}
    return arr.join(',');
  }
  //提交更新操作
  create(){
    const _this = this;
    let o = {
      h_id:'',
      address: '',
      ali_app_private_key: '',
      ali_appid: '',
      ali_merchant_public_key:  '',
      ali_pid: '',
      ali_public_key:  '',
      can_yuyue_day: '',
      city_id:  '',
      contact:  '',
      desc:  '',
      district_id:  '',
      email: '',
      has_da_keshi: '',
      his_interface: '',
      his_private_key: '',
      his_public_key: '',
      his_trans_type: '',
      intr:  '',
      lock_db:  '',
      medical_deposit_price:'',
      name:  '',
      pay_all:  '',
      pay_method:  '',
      phone:  '',
      province_id:  '',
      qualified:  '',
      qualified_code:  '',
      telphone:  '',
      type:  '',
      wx_appid:  '',
      wx_appsecret:  '',
      wx_cert:  '',
      wx_key:  '',
      wx_key_cert:  '',
      wx_mch_id:  '',
      wx_sub_appid:  '',
      wx_sub_appsecret:  '',
      wx_sub_mch_id:  '',
      wx_token:  '',
      yuyue_method:  '',
      yuyue_quxiao:  '',
      yuyue_quxiao_time:  '',
      zhuyuan_yujiao_jinge:  ''
    };
    for(let k in o){o[k] = this.state[k]||''}
    o.ali_qr = this.getUploadPath(this.state.ali_qr);
    o.banner = this.getUploadPath(this.state.banner);
    o.logo = this.getUploadPath(this.state.logo);
    o.wx_qr = this.getUploadPath(this.state.wx_qr);
    o.mer_id = this.state.treeSelectValue.value;
    o.province = o.province_id;
    o.city = o.city_id;
    o.area = o.district_id;
    //验证输入合法性
    if(!o.name || o.name.length <2 || o.name.length > 50){
      message.error('请输入正确医院名称');
      return;
    }
    if(o.mer_id === '' || o.mer_id === undefined){
      message.error('请选择商户，如无商户请联系管理员进行添加');
      return;
    }
    if(!o.logo || o.logo.length <2 ){
      message.error('请上传医院LOGO ICON');
      return;
    }
    if(!o.banner || o.banner.length <2){
      message.error('请上传医院banner图');
      return;
    }
    if(!o.desc || o.desc.length <2){
      message.error('请输入医院简介');
      return;
    }
    if(!o.medical_deposit_price){
      o.medical_deposit_price = 0
    }
    //_this.setState({loading:true})
    http.post('/Backend/hospital/update_hospital',o).then(function(data){
      data = data.data;
      if(data.code === 0){
        message.success(data.msg);
        _this.props.history.go(-1);
      }else{
        message.error(data.msg);
        _this.setState({
            btnloading: false
        });
      }
    })
  }

  //下一步
  next() {
    const current = this.state.current + 1;
    this.setState({ current });
  }
  //上一步
  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }

  submit(){
    if(this.state.current > 1){
      this.create();
    }else{
      this.next();
    }
  }

  //选择医院经营性质
  onChangeXingzhi = (e) => {
    this.setState({
      qualified_code: e.target.value,
    });
  }
  //改变省
  handleProvinceChange = (value) => {
    this.setState({
      province_id : value
    });
    this.fetchAreaPid(value,'province');
  }
  //改变市
  onCityChange = (value) => {
    this.setState({
      city_id: value,
    });
    this.fetchAreaPid(value,'city');
  }
  //改变区县
  onDistrictChange = (value) => {
    this.setState({
      district_id: value,
    });
  }
  onChangeInput = prop => e => {    
      this.setState({
          [prop]: e.target.value.trim()
      });
  }
  //回车事件
  KeyUpHandle(e){
      if (e.keyCode !== 13) {
          return;
      }
      this.submit();
  }
  onChangeRadio = prop => e => {
      this.setState({
          [prop]: e.target.value
      });
  }
  //获取医院等级
  async fetch(){
    let _this = this;
    await http.post('/Backend/sysDictionary/read',{pid:'171'}).then(function(data){
      data = data.data;
      _this.setState({
        hospitalDengji : data.rows||[],
        type : _this.state.type || (data.rows.length ? data.rows[0].id : undefined)
      })
    }).then(_this.fetchHospitalLeixing.bind(_this))
  }
  //获取医院类型
  async fetchHospitalLeixing(){
    let _this = this;
    await http.post('/Backend/sysDictionary/read',{pid:'189'}).then(function(data){
      data = data.data;
      _this.setState({
        hospitalLeixin : data.rows||[],
        qualified : _this.state.qualified || (data.rows.length ? data.rows[0].id : undefined)
      })
    }).then(_this.fetchHospitalXingzhi.bind(_this))
  }
  //获取医院经营性质
  async fetchHospitalXingzhi(){
    let _this = this;
    await http.post('/Backend/sysDictionary/read',{pid:'200'}).then(function(data){
      data = data.data;
      _this.setState({
        hospitalXingzhi : data.rows||[],
        qualified_code : _this.state.qualified_code || (data.rows.length ? data.rows[0].id : undefined)
      })
    }).then(() =>  _this.fetchArea.bind(_this)());
  }
  //获取省市区地理位置
  async fetchArea(){
    let _this = this;
    await http.post('/Backend/area/get_default').then(function(data){
      data = data.data;
      if(_this.state.province_id){
        _this.setState({
          province : data.province
        },_this.fetchAreaPid.bind(_this,_this.state.province_id,'province',_this.state.city_id,_this.state.district_id))
      }else{
        _this.setState(data)
      }
      
    }).then(_this.fetchMerchant.bind(_this,{mer_id : CONFIG.S.get('mer_id')||''}))
  }
  //获取子区域
  async fetchAreaPid(pid,type,city_id,district_id){
    let _this = this;
    await http.post('/Backend/area/get_by_pid',{pid}).then(function(data){
      data = data.data;
      if(type === 'province'){
        _this.setState({
          city : data.data,
          city_id : city_id||data.default_id
        });
        _this.fetchAreaPid(city_id||data.default_id,'city',city_id,district_id);
      }else if(type === 'city'){
        _this.setState({
          district : data.data,
          district_id : district_id||data.default_id
        })
      }

    })
  }
  //获取商户列表
  async fetchMerchant(params = {}){
    let _this = this;
    await http.post('/Backend/merchant/find',{...params}).then(function(data){
      data = data.data;
      selectData = data.rows||[];
      _this.setState({
        treeSelectData : _this.createSelect(CONFIG.S.get('mer_pid')||"0")
      })
    }).then(()=>{})
  }
  //生成商户选择树
  createSelect(pid){
    let temp = [];
    for(let i = 0;i<selectData.length;i++){
      if(selectData[i].p_id===pid){
        temp.push({
          title : selectData[i].name,
          sort : selectData[i].sort,
          value : selectData[i].id,
          key : selectData[i].p_id+'_'+selectData[i].id,
          children : [],
          ...selectData[i]
        });
      }
    }
    if(temp.length){
      temp = temp.sort(function(a,b){
        return a.sort > b.sort ? 1 : -1
      });
      for(let j = 0;j<temp.length;j++){
        temp[j].children=this.createSelect(temp[j].id);
      }
    }
    return temp;
  }
  //选择商户
  onChangeTreeSelectData(v){
    this.setState({treeSelectValue:v})
  }
  componentDidMount(){    
    this.fetch();
  }
  //选择医院等级
  changeDengji(v){
    this.setState({type:v})
  }
  //选择医院类型
  changeLeixin(v){
    this.setState({qualified : v})
  }
  render() {
    const { current } = this.state;
    let steps = [
          {
            title: '基础信息',
            content: () =>{
              return <div style={{padding:'24px 30px'}}>
                <Form>
              <FormItem
                {...formItemLayout}
                label="医院名称"
                validateStatus=""
                help=""
              >
                <Input placeholder="请输入医院名称" value={this.state.name} id="name" 
            onKeyUp={this.KeyUpHandle.bind(this)} maxLength={40}
            onChange={this.onChangeInput('name')}/>
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="医院等级"
                validateStatus=""
              >
                <Select value={this.state.type} placeholder="请选择医院等级" onChange={this.changeDengji.bind(this)}>
                  {
                    this.state.hospitalDengji.map((v) =>(
                      <Option value={v.id} key={v.id}>{v.text}</Option>
                    ))
                  }
                </Select>
              </FormItem>
              <FormItem
                {...formItemLayout}
                label="医院类型"
                validateStatus=""
              >
                <Select value={this.state.qualified} placeholder="请选择医院类型" onChange={this.changeLeixin.bind(this)}>
                  {
                    this.state.hospitalLeixin.map((v) =>(
                      <Option value={v.id} key={v.id}>{v.text}</Option>
                    ))
                  }
                </Select>
              </FormItem>
              <FormItem
                {...formItemLayout}
                label="所属商户"
                hasFeedback
                validateStatus=""
                help=""
              >
                <TreeSelect
                  style={{ width: '100%'}}
                  value={this.state.treeSelectValue}
                  treeData={this.state.treeSelectData}
                  placeholder="请选择所属服务商户"
                  treeDefaultExpandAll
                  labelInValue
                  onChange={this.onChangeTreeSelectData.bind(this)}
                />
              </FormItem>

              
              <FormItem
                {...formItemLayout}
                label="省市区"
                hasFeedback
                validateStatus=""
                help=""
              >
                <Select
                    value={this.state.province_id}
                    style={{ width: 120,marginRight:'4px' }}
                    onChange={this.handleProvinceChange}
                  >
                    {this.state.province.map(v => <Option key={v.id} value={v.id}>{v.text}</Option>)}
                  </Select>
                  <Select
                    style={{ width: 120,marginRight:'4px'  }}
                    value={this.state.city_id}
                    onChange={this.onCityChange}
                  >
                    {this.state.city.map(v => <Option key={v.id} value={v.id}>{v.text}</Option>)}
                  </Select>
                  <Select
                    style={{ width: 120,marginRight:'4px'  }}
                    value={this.state.district_id}
                    onChange={this.onDistrictChange}
                  >
                    {this.state.district.map(v => <Option key={v.id} value={v.id}>{v.text}</Option>)}
                  </Select>
              </FormItem>
              <FormItem
                {...formItemLayout}
                label="医院地址"
                hasFeedback
                validateStatus=""
              >
                <Input placeholder="请输入医院地址" value={this.state.address} id="address" 
            onKeyUp={this.KeyUpHandle.bind(this)}
            onChange={this.onChangeInput('address')} />
              </FormItem>

              
              <FormItem
                {...formItemLayout}
                label="经营性质"
                hasFeedback
                validateStatus=""
              >
                <RadioGroup onChange={this.onChangeXingzhi} value={this.state.qualified_code}>
                  {
                    this.state.hospitalXingzhi.map((v) =>(
                      <Radio value={v.id} key={v.id}>{v.text}</Radio>
                    ))
                  }
                </RadioGroup>
              </FormItem>
              <FormItem
                {...formItemLayout}
                label="联系人"
                validateStatus=""
              >
                <Input placeholder="请输入联系人姓名"  value={this.state.contact} id="contact" 
            onKeyUp={this.KeyUpHandle.bind(this)}
            onChange={this.onChangeInput('contact')} />
              </FormItem>
              <FormItem
                {...formItemLayout}
                label="联系方式"
                validateStatus=""
              >
                <Input placeholder="请输入联系电话"  value={this.state.phone} id="phone" 
            onKeyUp={this.KeyUpHandle.bind(this)}
            onChange={this.onChangeInput('phone')} />
              </FormItem>
              <FormItem
                {...formItemLayout}
                label="联系邮箱"
                validateStatus=""
              >
                <Input placeholder="请输入联系人email"  value={this.state.email} id="email" 
            onKeyUp={this.KeyUpHandle.bind(this)}
            onChange={this.onChangeInput('email')} />
              </FormItem>
              <FormItem
                {...formItemLayout}
                label="座机号码"
                validateStatus=""
              >
                <Input placeholder="请输入单位联系电话"  value={this.state.telphone} id="telphone" 
            onKeyUp={this.KeyUpHandle.bind(this)}
            onChange={this.onChangeInput('telphone')} />
              </FormItem>

            </Form>

              </div>;
            }
          }, {
            title: '医院简介',
            content:  () =>{
              return <div style={{padding:'24px 30px'}}>
                <Form>
              <FormItem
                {...formItemLayout}
                label="医院icon图标"
                validateStatus=""
              >
                <Upload {...this.state.uploadlogo}>
                  <Button>
                    <Icon type="upload" /> 200*200等比率
                  </Button>
                </Upload>
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="医院banner图"
                validateStatus=""
              >
                <Upload {...this.state.uploadbanner}>
                  <Button>
                    <Icon type="upload" /> 750*290等比率
                  </Button>
                </Upload>
              </FormItem>


              <FormItem
                {...formItemLayout}
                label="医院介绍"
                validateStatus=""
                help=""
              >
                <TextArea placeholder="请输入医院介绍" autosize  value={this.state.desc} id="desc" 
            onKeyUp={this.KeyUpHandle.bind(this)}
            onChange={this.onChangeInput('desc')} />
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="就诊流程"
                validateStatus=""
                help=""
              >
                <TextArea placeholder="请输入医院就诊流程" autosize  value={this.state.intr} id="intr" 
            onKeyUp={this.KeyUpHandle.bind(this)}
            onChange={this.onChangeInput('intr')}/>
              </FormItem>



            </Form>

              </div>;
            }
          }, {
            title: '配置信息',
            content:  () =>{
              return <div style={{padding:'24px 30px'}}>
              
                <Form>
          <Card title="基本配置" headStyle={{backgroundColor:'#e8e8e8'}} style={{marginBottom:'10px'}}>
                <FormItem
                {...formItemLayout}
                label="是否有大小科室区分"
                hasFeedback
                validateStatus=""
              >
                <RadioGroup onChange={this.onChangeRadio('has_da_keshi')} value={this.state.has_da_keshi} id="has_da_keshi">
                  <Radio value={'1'}>没有</Radio>
                  <Radio value={'2'}>有</Radio>
                </RadioGroup>
              </FormItem>
               <FormItem
                {...formItemLayout}
                label="预约挂号是否锁号"
                hasFeedback
                validateStatus=""
              >
                <RadioGroup onChange={this.onChangeRadio('lock_db')} id="lock_db" value={this.state.lock_db}>
                  <Radio value={'1'}>不锁定</Radio>
                  <Radio value={'2'}>锁定</Radio>
                </RadioGroup>
              </FormItem>

               <FormItem
                {...formItemLayout}
                label="是否可取消预约"
                hasFeedback
                validateStatus=""
              >
                <RadioGroup onChange={this.onChangeRadio('yuyue_quxiao')} id="yuyue_quxiao" value={this.state.yuyue_quxiao}>
                  <Radio value={'1'}>可以</Radio>
                  <Radio value={'2'}>不可以</Radio>
                </RadioGroup>
              </FormItem>

               <FormItem
                {...formItemLayout}
                label="取消预约截止时间节点"
                hasFeedback
                validateStatus=""
              >
                <RadioGroup onChange={this.onChangeRadio('yuyue_quxiao_time')} id="yuyue_quxiao_time" value={this.state.yuyue_quxiao_time}>
                  <Radio value={'1'}>任意时间</Radio>
                  <Radio value={'2'}>就诊日期前一天</Radio>
                </RadioGroup>
              </FormItem>

               <FormItem
                {...formItemLayout}
                label="预约挂号支付方式"
                hasFeedback
                validateStatus=""
              >
                <RadioGroup onChange={this.onChangeRadio('yuyue_method')} id="yuyue_method" value={this.state.yuyue_method}>
                  <Radio value={'1'}>在线立即支付</Radio>
                  <Radio value={'2'}>限时支付</Radio>
                  <Radio value={'3'}>到院支付取号</Radio>
                </RadioGroup>
              </FormItem>

               <FormItem
                {...formItemLayout}
                label="门诊缴费方式"
                hasFeedback
                validateStatus=""
              >
                <RadioGroup onChange={this.onChangeRadio('pay_method')} id="pay_method" value={this.state.pay_method}>
                  <Radio value={'1'}>在线支付</Radio>
                  <Radio value={'2'}>就诊卡预充值</Radio>
                </RadioGroup>
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="住院押金最低金额"
                validateStatus=""
                help=""
              >
                <Input placeholder="请输入单次最低预缴金额" value={this.state.zhuyuan_yujiao_jinge} id="zhuyuan_yujiao_jinge" 
            onKeyUp={this.KeyUpHandle.bind(this)}
            onChange={this.onChangeInput('zhuyuan_yujiao_jinge')}/>
              </FormItem>
              <FormItem
                {...formItemLayout}
                label="邮寄病历押金"
                validateStatus=""
                help=""
              >
                <Input placeholder="请输入邮寄病历押金"  value={this.state.medical_deposit_price} id="medical_deposit_price" 
            onKeyUp={this.KeyUpHandle.bind(this)}
            onChange={this.onChangeInput('medical_deposit_price')} />
              </FormItem>
              <FormItem
                {...formItemLayout}
                label="医院接口地址"
                validateStatus=""
                help=""
              >
                <Input placeholder="请输入API接口地址"  value={this.state.his_interface} id="his_interface" 
            onKeyUp={this.KeyUpHandle.bind(this)}
            onChange={this.onChangeInput('his_interface')} />
              </FormItem>
              <FormItem
                {...formItemLayout}
                label="接口数据传递类型"
                validateStatus=""
                help=""
              >
                <Input placeholder="请输入接口数据传递类型"  value={this.state.his_trans_type} id="his_trans_type" 
            onKeyUp={this.KeyUpHandle.bind(this)}
            onChange={this.onChangeInput('his_trans_type')}/>
              </FormItem>
              <FormItem
                {...formItemLayout}
                label="医院私钥"
                validateStatus=""
                help=""
              >
                <Input placeholder="请输入接口传递数据加密用的私钥"  value={this.state.his_private_key} id="his_private_key" 
            onKeyUp={this.KeyUpHandle.bind(this)}
            onChange={this.onChangeInput('his_private_key')} />
              </FormItem>
              <FormItem
                {...formItemLayout}
                label="医院公钥"
                validateStatus=""
                help=""
              >
                <Input placeholder="请输入接口传递数据加密用的公钥"  value={this.state.his_public_key} id="his_public_key" 
            onKeyUp={this.KeyUpHandle.bind(this)}
            onChange={this.onChangeInput('his_public_key')} />
              </FormItem>
          </Card>


          <Card title="支付宝配置" headStyle={{backgroundColor:'#e8e8e8'}} style={{marginBottom:'10px'}}>      
               <FormItem
                {...formItemLayout}
                label="生活号二维码"
                validateStatus=""
              >
                <Upload {...this.state.uploadali_qr}>
                  <Button>
                    <Icon type="upload" /> 400*400等比率
                  </Button>
                </Upload>
              </FormItem>
              <FormItem
                {...formItemLayout}
                label="支付宝服务商pid"
                validateStatus=""
                help=""
              >
                <Input placeholder="请输入支付宝服务商pid"  value={this.state.ali_pid} id="ali_pid" 
            onKeyUp={this.KeyUpHandle.bind(this)}
            onChange={this.onChangeInput('ali_pid')} />
              </FormItem>
              <FormItem
                {...formItemLayout}
                label="医院支付宝appid"
                validateStatus=""
                help=""
              >
                <Input placeholder="医院支付宝appid"  value={this.state.ali_appid} id="ali_appid" 
            onKeyUp={this.KeyUpHandle.bind(this)}
            onChange={this.onChangeInput('ali_appid')} />
              </FormItem>
              <FormItem
                {...formItemLayout}
                label="支付宝私钥"
                validateStatus=""
                help=""
              >
                <Input placeholder="请输入支付宝私钥"  value={this.state.ali_app_private_key} id="ali_app_private_key" 
            onKeyUp={this.KeyUpHandle.bind(this)}
            onChange={this.onChangeInput('ali_app_private_key')} />
              </FormItem>
              <FormItem
                {...formItemLayout}
                label="支付宝公钥"
                validateStatus=""
                help=""
              >
                <Input placeholder="请输入支付宝公钥"  value={this.state.ali_public_key} id="ali_public_key" 
            onKeyUp={this.KeyUpHandle.bind(this)}
            onChange={this.onChangeInput('ali_public_key')} />
              </FormItem>
              
          </Card>


          <Card title="微信配置" headStyle={{backgroundColor:'#e8e8e8'}} style={{marginBottom:'10px'}}>      
               <FormItem
                {...formItemLayout}
                label="微信公众号二维码"
                validateStatus=""
              >
                <Upload {...this.state.uploadwx_qr}>
                  <Button>
                    <Icon type="upload" /> 400*400等比率
                  </Button>
                </Upload>
              </FormItem>
              <FormItem
                {...formItemLayout}
                label="微信wx_appid"
                validateStatus=""
                help=""
              >
                <Input placeholder="请输入微信wx_appid"  value={this.state.wx_appid} id="wx_appid" 
            onKeyUp={this.KeyUpHandle.bind(this)}
            onChange={this.onChangeInput('wx_appid')} />
              </FormItem>
              <FormItem
                {...formItemLayout}
                label="微信wx_key"
                validateStatus=""
                help=""
              >
                <Input placeholder="微信wx_key"  value={this.state.wx_key} id="wx_key" 
            onKeyUp={this.KeyUpHandle.bind(this)}
            onChange={this.onChangeInput('wx_key')} />
              </FormItem>
              <FormItem
                {...formItemLayout}
                label="微信wx_appsecret"
                validateStatus=""
                help=""
              >
                <Input placeholder="微信wx_appsecret"  value={this.state.wx_appsecret} id="wx_appsecret" 
            onKeyUp={this.KeyUpHandle.bind(this)}
            onChange={this.onChangeInput('wx_appsecret')} />
              </FormItem>
              <FormItem
                {...formItemLayout}
                label="微信wx_token"
                validateStatus=""
                help=""
              >
                <Input placeholder="请输入微信wx_token"  value={this.state.wx_token} id="wx_token" 
            onKeyUp={this.KeyUpHandle.bind(this)}
            onChange={this.onChangeInput('wx_token')} />
              </FormItem>
              <FormItem
                {...formItemLayout}
                label="微信wx_mch_id"
                validateStatus=""
                help=""
              >
                <Input placeholder="请输入微信wx_mch_id"  value={this.state.wx_mch_id} id="wx_mch_id" 
            onKeyUp={this.KeyUpHandle.bind(this)}
            onChange={this.onChangeInput('wx_mch_id')} />
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="微信wx_sub_appid"
                validateStatus=""
                help=""
              >
                <Input placeholder="请输入微信wx_sub_appid"  value={this.state.wx_sub_appid} id="wx_sub_appid" 
            onKeyUp={this.KeyUpHandle.bind(this)}
            onChange={this.onChangeInput('wx_sub_appid')} />
              </FormItem>
               <FormItem
                {...formItemLayout}
                label="微信wx_sub_appid"
                validateStatus=""
                help=""
              >
                <Input placeholder="请输入微信wx_sub_appsecret"  value={this.state.wx_sub_appsecret} id="wx_sub_appsecret" 
            onKeyUp={this.KeyUpHandle.bind(this)}
            onChange={this.onChangeInput('wx_sub_appsecret')} />
              </FormItem>

              <FormItem
                {...formItemLayout}
                label="微信wx_sub_mch_id"
                validateStatus=""
                help=""
              >
                <Input placeholder="请输入微信wx_sub_mch_id"  value={this.state.wx_sub_mch_id} id="wx_sub_mch_id" 
            onKeyUp={this.KeyUpHandle.bind(this)}
            onChange={this.onChangeInput('wx_sub_mch_id')} />
              </FormItem>
              <FormItem
                {...formItemLayout}
                label="微信证书名称"
                validateStatus=""
                help=""
              >
                <Input placeholder="请输入微信证书名称"  value={this.state.wx_cert} id="wx_cert" 
            onKeyUp={this.KeyUpHandle.bind(this)}
            onChange={this.onChangeInput('wx_cert')} />
              </FormItem>
              <FormItem
                {...formItemLayout}
                label="微信KEY证书"
                validateStatus=""
                help=""
              >
                <Input placeholder="请输入微信KEY证书"  value={this.state.wx_key_cert} id="wx_key_cert" 
            onKeyUp={this.KeyUpHandle.bind(this)}
            onChange={this.onChangeInput('wx_key_cert')} />
              </FormItem>
          </Card>


            </Form>
              </div>;
            }
          }];
    return (
      <div>
        <Steps current={current} style={{maxWidth:'750px',margin:'16px auto'}}>
          {steps.map(item => <Step key={item.title} title={item.title} />)}
        </Steps>
        <div className="steps-content">{steps[current].content(this)}</div>
        <div className="steps-action" style={{textAlign:'left',paddingBottom:'50px'}}>
        <Row gutter={48}>
        <Col className="gutter-row" span={7}></Col>
          <Col className="gutter-row" span={10}>
          {
            current < steps.length - 1
            && <Button type="primary" onClick={() => this.next()}>下一步</Button>
          }
          {
            current === steps.length - 1
            && <Button type="primary" onClick={this.create.bind(this)} loading={this.state.loading}>提交</Button>
          }
          {
            current > 0
            && (
            <Button style={{ marginLeft: 8 }} onClick={() => this.prev()}>
              上一步
            </Button>
            )
          }
          </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withRouter(CreateHospital)