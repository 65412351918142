import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
    backgroundColor: {
        primary: '#fff371',
        dark: '#3e3a39',
        lightDark: '#595757',
        deepGray: '#717071',
        lightGray: '#f7f7f7',
    },
    lineColor: {
        primary: '#b4b5b5'
    },typography: {
        useNextVariants: true,
      },

})