import React from "react";
import { Row, Col,Button } from 'antd';
import basic from './basic.css';
class LayoutTitle extends React.Component {
	render(){
        const {opt} = this.props;
	return	<Row gutter={0}>
      <Col className={basic.titlerow} span={24}>
        <div className={basic.titlebox+' clearfix'}>
        	<div className={basic.pull_right}>
        		<Button type="primary" icon="plus" onClick={opt.btn[0].click}>{opt.btn[0].text}</Button>
        	</div>
        	<div className="clearfix">
        		<h5 className={basic.titleh5}>{this.props.name||''}</h5>
        		<div className={basic.pull_right +' '+basic.console_title_static}></div>
        	</div>
        </div>
      </Col>
    </Row>



	}
}


export default LayoutTitle;