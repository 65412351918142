import React from "react";
import { Table, Divider, Popconfirm,Button,Icon  } from 'antd';
import LayoutTitle from '../../../components/layout/title';
import CONFIG from '../../../config';
import http from '../../../server';
import { withRouter } from "react-router";

class Merchant extends React.Component {
	state = {
    data: [],
    loading: true,
    columns : [{
        title: '商户名称',
        dataIndex: 'name',
        render: (name,icon) => (<span><Icon type={icon} />{name}</span>),
        width: '20%',
      }, {
        title: '医院列表',
        dataIndex: 'list'
      }, {
        title: '操作',
        key: 'action',
        render: (text, record) => (
          <span>
            <Button type="primary" onClick={this.edit.bind(this,text,record)} style={{height:'auto'}}>编辑</Button>
            <Divider type="vertical" />
            <Popconfirm placement="topRight" title={'确认删除该功能吗?'+(text.children&&text.children.length ? '相应的子功能也会删除!':'')} onConfirm={this.delete.bind(this,text,record)} okText="确认" cancelText="取消">
              <Button type="danger" style={{height:'auto'}}>删除</Button>
            </Popconfirm>
          </span>
        ),
        width: '20%',
      }] 
  };
  edit(text,record){
    this.props.history.push('/privilege/merchant/edit',text);
  }
  delete(text,record){
    console.log(text);
  }
  handleTableChange = (pagination, filters, sorter) => {
    
  }
async fetch(params = {}){
    let _this = this;
    await http.post('/Backend/merchant/find',{...params}).then(function(data){
      data = data.data;
      var selectData=data.rows;
      var createSelect = function(pid){
        let temp = [];
        for(let i = 0;i<selectData.length;i++){
          if(selectData[i].p_id===pid){
            temp.push({
              key : selectData[i].p_id+'-'+selectData[i].id,
              children : [],
              ...selectData[i]
            });
          }
        }
        if(temp.length){
          temp = temp.sort(function(a,b){
            return a.sort > b.sort ? 1 : -1
          });
          for(let j = 0;j<temp.length;j++){
            temp[j].children=createSelect(temp[j].id);
            temp[j].children = temp[j].children.length ? temp[j].children : undefined;
          }
        }
        return temp;
      }
        _this.setState({
          loading: false,
          data: createSelect(CONFIG.S.get('mer_pid')||"0")
        });
    })
  }
  createHospital(){
    this.props.history.push('/privilege/merchant/create')
  }
  componentDidMount() {
    this.fetch({mer_id : CONFIG.S.get('mer_id')||''});
  }
	render(){
		const createHospital = this.createHospital.bind(this)
	return <div>
			<LayoutTitle name="商户列表" opt={{btn : [{click:createHospital,text:'添加商户'}]}} />
			<div style={{paddingTop:'8px'}}>
				<div style={{paddingTop:'8px'}}>
					{this.state.data.length ? <Table
				        columns={this.state.columns}
				        rowKey={record => record.id}
				        dataSource={this.state.data}
				        loading={this.state.loading}
				        onChange={this.handleTableChange}
                defaultExpandAllRows={true}
                pagination={false}
				      />
          : '暂无数据'}
				</div>
			</div>
		</div>
	}
}

export default withRouter(Merchant);