import React from "react";
import { Table, Divider, Popconfirm,Select,TreeSelect   } from 'antd';
import LayoutTitle from '../../../components/layout/title';
import CONFIG from '../../../config';
import http from '../../../server';
import { withRouter } from "react-router";

const Option = Select.Option;
var selectData=[];
const dengji = CONFIG.dengji;
class Doctor extends React.Component {
	state = {
    selectInput : undefined,
    h_id : '0',
    dep_id : '0',
    data: [],
    mer : undefined,
    mer_id : undefined,
    loading: true,
    hospitalList : [],
    departmentList : [],
    treeSelectData : [],
    pagination: {
      pageSizeOptions : ['10','20','30','50','100'],
      showSizeChanger : true
    },
    columns : [{
        title: '姓名',
        dataIndex: 'doc_name',
        width: '10%',
      }, {
        title: '职称',
        dataIndex: 'doc_title',
        width: '25%',
        render : (text,record)=>{
          return  (record.doc_duty?CONFIG.dengjizhiwu[record.doc_duty].v : '')+ ' '+dengji[record.doc_type][record.doc_title]
        }
      }, {
        title: '职业',
        dataIndex: 'doc_type',
        width: '12%',
        render : (text,record)=>{
          return dengji[record.doc_type][0]
        }
      },{
        title: '科室',
        dataIndex: 'dep_name',
        width: '12%',
      },{
        title: '所属医院',
        dataIndex: 'h_name'
      }, {
        title: '操作',
        key: 'action',
        render: (text, record) => (
          <span>
              <span onClick={this.edit.bind(this,text,record)} style={{color:'#1890ff',cursor:'pointer'}}>编辑</span>
              <Divider type="vertical" />
              <Popconfirm placement="topRight" title='确认删除该职工吗?' onConfirm={this.delete.bind(this,text,record)} okText="确认" cancelText="取消">
                <span style={{color:'#1890ff',cursor:'pointer'}}>删除</span>
              </Popconfirm>
            </span>
        ),
        width: '20%',
      }] 
  }
  handleChangeHospital = (v) => {
    this.setState({
      h_id : v,
      dep_id : '0'
    },()=>{
      this.fetch();
      this.fetchDepartment();
    })
  }
  handleChangeDepartment = (v) => {
    this.setState({
      dep_id : v
    },()=>this.fetch())
  }
  //分页
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.fetch({
      page_size: pagination.pageSize,
      page: pagination.current,
      ...filters,
    });
  }
  edit(text,record){
    this.props.history.push('/hospital/doctor/edit',record);
  }
  delete(text,record){
    let _this = this;
    http.post('/Backend/doctor/delete',{ids:record.id}).then(function(data){
      _this.fetch()
    })
  }
  
//获取医院列表
  async fetchHospital(config={}){
    let _this = this;
    await http.post('/Backend/hospital/find',{page:1,page_size:300,...config}).then(function(data){
      data = data.data;
      const pager = { ..._this.state.pagination };
      pager.total = parseInt(data.total);
      _this.setState({
        hospitalList:data.rows
      })
    })
  }
//获取科室列表
fetchDepartment(params = {}){
    let _this = this;
    params = {...params,h_id : (this.state.h_id === '0' ? '' : this.state.h_id)};
    http.post('/Backend/depart/find',{page_size: 100}).then(function(data){
      data = data.data;
        _this.setState({
          departmentList: data.rows
        });
    })
  }
async fetch(params = {}){
    let _this = this;
    _this.setState({
          loading: true});
    params = {...params,h_id : (!this.state.h_id||this.state.h_id === '0' ? '' : this.state.h_id),dep_id : (!this.state.dep_id||this.state.dep_id === '0' ? '' : this.state.dep_id)};
    await http.post('/Backend/doctor/find',{page_size: 10,...params}).then(function(data){
      data = data.data;
      const pagination = { ..._this.state.pagination };
      pagination.total = parseInt(data.total||'0');
        _this.setState({
          loading: false,
          data: data.rows,
          pagination,
        });
    })
  }

createSelect(pid){
  let temp = [];
  for(let i = 0;i<selectData.length;i++){
    if(selectData[i].p_id===pid){
      temp.push({
        title : selectData[i].name,
        sort : selectData[i].sort,
        value : selectData[i].id,
        key : selectData[i].p_id+'_'+selectData[i].id,
        children : [],
        ...selectData[i]
      });
    }
  }
  if(temp.length){
    temp = temp.sort(function(a,b){
      return a.sort > b.sort ? 1 : -1
    });
    for(let j = 0;j<temp.length;j++){
      temp[j].children=this.createSelect(temp[j].id);
    }
  }
  return temp;
}
getMerchant(params={}){
  let _this = this;
  http.post('/Backend/merchant/find',{...params}).then(function(data){
    data = data.data;
    selectData = data.rows||[];
    _this.setState({
      treeSelectData : _this.createSelect(CONFIG.S.get('mer_pid')||"0")
    })
  })
}
onChangeMerchant = (v) => {    
    this.setState({
      mer:v, mer_id : v.value,h_id:'0',
      dep_id : '0'
    },() =>this.fetchHospital({mer_id:v.value}));
  }
  createDoctor(){
    this.props.history.push('/hospital/doctor/create',{h_id:this.state.h_id,dep_id:this.state.dep_id})
  }
  componentDidMount() {
    let mer_id = CONFIG.S.get('mer_id')||'';
    this.fetch({mer_id});
    this.fetchHospital({mer_id});
    if(!mer_id || mer_id === '1'){
      this.getMerchant()
    }
  }
  componentWillUnmount = () => {
    this.setState = (state,callback)=>{
      return;
    };
}
	render(){
		const createDoctor = this.createDoctor.bind(this)
	return <div>
			<LayoutTitle name="职工列表" opt={{btn : [{click:createDoctor,text:'添加职工'}]}} />
			<div style={{paddingTop:'8px'}}>
        <div style={{position:'relative'}}>
          <TreeSelect
          style={{ width: 200,display:(CONFIG.S.get('mer_pid') === '0' ? 'inline-block':'none'),'marginRight':'10px'}}
          value={this.state.mer}
          treeData={this.state.treeSelectData}
          placeholder="请选择商户"
          treeDefaultExpandAll
          labelInValue
          onChange={this.onChangeMerchant}
        />
          <Select
            showSearch
            value={this.state.h_id}
            style={{ width: 200 ,marginRight:'10px'}}
            placeholder="全部医院"
            optionFilterProp="children"
            onChange={this.handleChangeHospital.bind(this)}
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
          <Option value="0" key="hospitl0">全部医院</Option>
          {
            this.state.hospitalList.map((v)=>(
              <Option value={v.id} key={v.id}>{v.name}</Option>
            ))
          }
          </Select>
          <Select
            showSearch
            value={this.state.dep_id}
            style={{ width: 200 }}
            placeholder="全部科室"
            optionFilterProp="children"
            onChange={this.handleChangeDepartment.bind(this)}
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
          <Option value="0" key="department0">全部科室</Option>
          {
            this.state.departmentList.map((v)=>(
              <Option value={v.id} key={v.id}>{v.dep_name}</Option>
            ))
          }
          </Select>

        </div>
				<div style={{paddingTop:'8px'}}>
					<Table
				        columns={this.state.columns}
				        rowKey={record => record.id}
				        dataSource={this.state.data}
				        loading={this.state.loading}
				        onChange={this.handleTableChange}
                pagination={this.state.pagination}
				      />
				</div>
			</div>
		</div>
	}
}

export default withRouter(Doctor);