import React from "react";
import { withStyles } from '@material-ui/core/styles';
import { Input, Icon,message } from 'antd';
import './Login.css';
import { Button } from 'antd';
import CONFIG from '../../config';
import reqwest from 'reqwest';
import { withRouter } from "react-router";
const logo = require('../../assets/img/logo.jpg')

const back = require('../../assets/img/back.png')

const styles = theme => ({
    backgroundColor: {
        display: 'flex',
        position: 'relative',
        height: '100vh',
        backgroundColor: '#f7f7f7',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'url(' + back + ')'
    },
    logo: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center'
    },
    form: {
        width: '60vh',
        height: '60vh',
        backgroundColor: 'rgb(59,181,142)',
        borderRadius: '2%',
        boxShadow: theme.shadows[11],
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative'
    },
    img: {
        height: '10vh'
    },
    logoImg: {
        height: '20vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    formContent: {
        flex: 1,
        height: '70%',
        width: '100%',
    },
    footer: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        height: '10vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    footerContent: theme.typography.body1,
    formContentInput: {
        padding: '3% 13%',
        display:'flex'
    },
    formFooter: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        height: '8vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    formFooterContent: {
        color: '#f0f0f0',
        fontSize: '1.5vh'
    },
    yzmimg : {
        flex:1,
        cursor:'pointer',margin:'0 0 0 5px',borderRadius:'3px'
    },
    loginBtn : {height:'50px',cursor:'pointer'}

});

class Login extends React.Component {
    constructor(props) {

        super(props)
        this.state = {
            userName: '',
            password: '',
            code:'',
            loading: false,
            verify : Math.random(),
            remember : 1
        };
    }
    emitEmpty = (e) => {
        this[e+'Input'].focus();
        this.setState({
            [e]: ''
        });
    }
    onChange = prop => e => {
        this.setState({
            [prop]: e.target.value.trim()
        });
    }
    //回车事件
    KeyUpHandle(e){
        if (e.keyCode !== 13) {
            return;
        }
        this.submitLogin();
    }
    submitLogin = () => {
        if(!this.state.userName){
            message.error('请输入用户名');
            this['userNameInput'].focus();
            return;
        }
        if(!this.state.password){
            message.error('请输入密码');
            this['passwordInput'].focus();
            return;
        }
        if(!this.state.code){
            message.error('请输入验证码');
            this['codeInput'].focus();
            return;
        }
        this.setState({
            loading: true
        });
        reqwest({
          url: CONFIG.url.lc_url+'Backend/auth/login',
          method: 'post',
          contentType: 'application/x-www-form-urlencoded',
          crossOrigin: true,
          withCredentials: true,
          data: {
            username : this.state.userName,
            password : this.state.password,
            remember : this.state.remember,
            code : this.state.code
          },
          type: 'json',
        }).then((data) => {
            if(data.code === -1){
                message.error(data.msg);
                this.setState({
                    loading: false,
                    code: '',
                    verify : Math.random()
                  });
                this['codeInput'].focus();                
            }else{
                message.success(data.msg);
                data.data.user_id = data.data.id;
                delete data.data.id;
                for(let k in data.data){
                  CONFIG.S.set(k,data.data[k]);  
                }
                this.props.history.push('/');
            }
          
        });
    }
    //更换验证码
    changeVerify(){
        this.setState({verify : Math.random()});
    }

    render() {
        const {classes} = this.props
        const {userName, password, loading,code} = this.state;
        const suffix1 = userName ? <Icon type="close-circle" style={{
            fontSize: '1.8vh'
        }} onClick={() => this.emitEmpty('userName')} /> : null;
        const suffix2 = password ? <Icon type="close-circle" style={{
            fontSize: '1.8vh'
        }} onClick={() => this.emitEmpty('password')} /> : null;
        const suffix3 = code ? <Icon type="close-circle" style={{
            fontSize: '1.8vh'
        }} onClick={() => this.emitEmpty('code')} /> : null;
        return (
            <div className={classes.backgroundColor} >
            <div className={classes.logo}>
        
      <div className={classes.form}>
      <div className={classes.logoImg}>
 <img src={logo} className={classes.img} alt="logo"/></div>
 <div className={classes.formContent}> 
 <div className={classes.formContentInput}> 
<Input
            placeholder="账号"
            prefix={<Icon type="user" style={{
                color: 'rgba(0,0,0,.25)',
                fontSize: '1.8vh'
            }} />}
            suffix={suffix1}
            value={userName}
            onKeyUp={this.KeyUpHandle.bind(this)}
            onChange={this.onChange('userName')}
            ref={node => this.userNameInput = node}
            style={{flex:1,height:'50px'}}
            /></div>
            <div className={classes.formContentInput}> 
            <Input
            placeholder="密码"
            prefix={<Icon type="lock" style={{
                color: 'rgba(0,0,0,.25)',
                fontSize: '1.8vh'
            }} />}
            onKeyUp={this.KeyUpHandle.bind(this)}
            suffix={suffix2}
            value={password}
            onChange={this.onChange('password')}
            type="password"
            ref={node => this.passwordInput = node}
            style={{flex:1,height:'50px'}}
            /></div>
            <div className={classes.formContentInput}> 
            <Input
            placeholder="验证码"
            prefix={<Icon type="key" style={{
                color: 'rgba(0,0,0,.25)',
                fontSize: '1.8vh'
            }} />}
            onKeyUp={this.KeyUpHandle.bind(this)}
            suffix={suffix3}
            value={code}
            onChange={this.onChange('code')}
            type="text"
            ref={node => this.codeInput = node}
            style={{flex:1,height:'50px'}}
            /><img src={CONFIG.url.la_url+'Mp/Auth/verify/rand/'+this.state.verify} title="看不清，点击更换" onClick={this.changeVerify.bind(this)} className={classes.yzmimg} alt="验证码图片"/></div>
            <div className={classes.formContentInput}> 
           {password && userName ? <Button type="default" className={classes.loginBtn} block loading={loading} onClick={this.submitLogin}>{loading ? '' : '登录'}</Button> : null}</div>
 <div className={classes.formFooter}><span className={classes.formFooterContent}>医院后台管理系统</span></div></div>
      </div>
             </div>
             <div className={classes.footer}><span className={classes.footerContent} style={{
                fontSize: '2vh'
            }}>Copyright 2018 by Msaid.CN</span></div>
</div>
        )
    }
}

export default withStyles(styles)(withRouter(Login));