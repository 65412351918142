import React from "react";
import {  Button, message,Form, Input, Row, Col, TreeSelect   } from 'antd';
//import CONFIG from '../../../config';
import http from '../../../server';
//import reqwest from 'reqwest';
import { withRouter } from "react-router";
const { TextArea } = Input;
const FormItem = Form.Item;
const SHOW_PARENT = TreeSelect.SHOW_PARENT;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 7 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
};

var selectData=[];

class CreateHospital extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading : false,
      value: undefined,
      show : 1,
      name : '',
      icon : '',
      status : 1,
      sort : 99,
      url : '',
      treeSelectData : [],
    };
  }
  componentDidMount(){    
    this.fetch();
  }

createSelect(pid){
  let temp = [];
  for(let i = 0;i<selectData.length;i++){
    if(selectData[i].pid===pid){
      temp.push({
        title : selectData[i].name,
        sort : selectData[i].sort,
        value : selectData[i].id,
        key : selectData[i].pid+'-'+selectData[i].id,
        children : [],
        ...selectData[i]
      });
    }
  }
  if(temp.length){
    temp = temp.sort(function(a,b){
      return a.sort > b.sort ? 1 : -1
    });
    for(let j = 0;j<temp.length;j++){
      temp[j].children=this.createSelect(temp[j].id);
    }
  }
  return temp;
}
//寻找子级
tranSelect(pid){
  let temp = [];
  for(var i = 0;i<selectData.length;i++){
    if(selectData[i].pid===pid){
      temp.push(selectData[i]);
    }
  }
  let temp2 = [],temp3;
  if(temp.length){
    for(let j = 0;j<temp.length;j++){
      temp3 = this.tranSelect(temp[j].id);
      temp2 = [...temp2,...temp3];
    }
  }
  return [...temp,...temp2];
}
//寻找父级
preSelect(pid){
  if(pid === '0')return [];
  let temp = [];
  for(var i = 0;i<selectData.length;i++){
    if(selectData[i].id===pid){
      temp.push(selectData[i]);
      break;
    }
  }
  let temp2 = [],temp3;
  if(temp.length){
    for(let j = 0;j<temp.length;j++){
      temp3 = this.preSelect(temp[j].pid);
      temp2 = [...temp2,...temp3];
    }
  }
  return [...temp,...temp2];
}
async fetch(){
    let _this = this;
    await http.post('/Backend/sysFunc/find',{}).then(function(data){
      data = data.data;
      selectData = data.rows||[];
      _this.setState({
        treeSelectData : _this.createSelect("0")
      })
    })
  }
  onChange = (value) => {    
    this.setState({ value});
  }
  onChangeXingzhi = (e) => {
    this.setState({
      show: e.target.value,
    });
  }
  onChangeInput = prop => e => {    
      this.setState({
          [prop]: e.target.value.trim()
      });
  }
  //回车事件
  KeyUpHandle(e){
      if (e.keyCode !== 13) {
          return;
      }
      this.submit();
  }
  //提交
  submit(){
    let _this = this,temp=[];
    if(this.state.value){
      let arr = [],arrString;
      for(let i = 0;i<selectData.length;i++){
        for(let j = 0;j<_this.state.value.length;j++){
          if(_this.state.value[j].value === selectData[i]['id']){
            arr.push(selectData[i]);
          }
        }
      }
      for(let i = 0;i<arr.length;i++){
        arrString = _this.tranSelect(arr[i]['id']);
        arr = [...arr,...arrString];
      }
      for(let i = 0;i<arr.length;i++){
        arrString = _this.preSelect(arr[i]['pid']);
        arr = [...arr,...arrString];
      }
      //去重
      for(let i = 0;i<arr.length;i++){
        let has = false;
        for(let j = 0;j<temp.length;j++){
          if(arr[i].id === temp[j]){has = true;break;}
        }
        if(!has){
          temp.push(arr[i].id);
        }
      }
    }
    if(!this.state.name || this.state.name.length < 2 || this.state.name.length > 10){
            message.error('请输入正确的名称');
            return;
        }
        if(!this.state.value||!temp.length){
            message.error('请选择分配权限');
            return;
        }
        // this.setState({
        //     loading: true
        // });
        http.post('/Backend/sysRole/create',{
            name : this.state.name,
            status : 1,
            desc : this.state.desc,
            detail : temp.join(','),
            mer_id : 0
          }).then((data) =>{
            data = data.data;
            if(data.code === 0){
              message.success(data.msg);
              _this.props.history.go(-1);
            }else{
              message.error(data.msg);
              _this.setState({
                  loading: false
              }); 
            }
          });
  }
  render() {
    const {loading}=this.state;
    return (
      <div>
        <div className="steps-content">
          <div style={{padding:'24px 30px'}}>
      <Form>
    <FormItem
      {...formItemLayout}
      label="角色名称"
      validateStatus=""
      help=""
    >
      <Input placeholder="请输入功能名称,2-10个字" value={this.state.name} id="name" 
            onKeyUp={this.KeyUpHandle.bind(this)} maxLength={10}
            onChange={this.onChangeInput('name')}/>
    </FormItem>
    
    <FormItem
      {...formItemLayout}
      label="分配权限"
      hasFeedback
      validateStatus=""
      help=""
    >
      <TreeSelect
        style={{ width: '100%'}}
        value={this.state.value}
        treeData={this.state.treeSelectData}
        placeholder="请为角色分配拥有的权限"
        treeDefaultExpandAll
        labelInValue
      treeCheckable={true}
      showCheckedStrategy={SHOW_PARENT}
        onChange={this.onChange}
      />
    </FormItem>
<FormItem
      {...formItemLayout}
      label="角色描述"
      validateStatus=""
      help=""
    >
      <TextArea placeholder="描述角色(选填)100字以内" autosize maxLength={100}  value={this.state.desc} id="desc" 
            onKeyUp={this.KeyUpHandle.bind(this)}
            onChange={this.onChangeInput('desc')}/>
    </FormItem>
  </Form>

    </div>
        </div>
        <div className="steps-action" style={{textAlign:'left',paddingBottom:'50px'}}>
        <Row gutter={48}>
        <Col className="gutter-row" span={7}></Col>
          <Col className="gutter-row" span={10}>
          <Button type="primary" loading={loading} onClick={this.submit.bind(this)}>{loading ? '提交中' : '提交'}</Button>          
          </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withRouter(CreateHospital)