import React from "react";
import { Table, Divider, Popconfirm,Button,Input  } from 'antd';
import LayoutTitle from '../../../components/layout/title';
import CONFIG from '../../../config';
import http from '../../../server';
import { withRouter } from "react-router";
const Search = Input.Search;

class User extends React.Component {
	state = {
    data: [],
    loading: true,
    pagination: {
      pageSizeOptions : ['10','20','30','50','100'],
      showSizeChanger : true
    },
    columns : [{
        title: '用户姓名',
        dataIndex: 'real_name',
        width: '20%',
      }, {
        title: '登录用户名',
        dataIndex: 'username',
        width: '20%',
      },{
        title: '所属商户',
        dataIndex: 'mername',
        width: '20%',
      },{
        title: '分配角色',
        dataIndex: 'role_name'
      }, {
        title: '操作',
        key: 'action',
        render: (text, record) => (
          <span>
            <Button type="primary" onClick={this.edit.bind(this,text,record)} style={{height:'auto'}}>编辑</Button>
            <Divider type="vertical" />
            <Popconfirm placement="topRight" title={'确认删除该功能吗?'+(text.children&&text.children.length ? '相应的子功能也会删除!':'')} onConfirm={this.delete.bind(this,text,record)} okText="确认" cancelText="取消">
              <Button type="danger" style={{height:'auto'}}>删除</Button>
            </Popconfirm>
          </span>
        ),
        width: '20%',
      }] 
  };
  //分页
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.fetch({
      page_size: pagination.pageSize,
      page: pagination.current,
      sort: sorter.field,
      order: sorter.order,
      ...filters,
    });
  }
  edit(text,record){
    this.props.history.push('/privilege/user/edit',text);
  }
  delete(text,record){
    console.log(text);
  }
  handleTableChange = (pagination, filters, sorter) => {
    
  }
async fetch(params = {}){
    let _this = this;
    await http.post('/Backend/sysUser/find',{page_size: 10,...params}).then(function(data){
      data = data.data;
      const pagination = { ..._this.state.pagination };
      pagination.total = parseInt(data.total||'0');
        _this.setState({
          loading: false,
          data: data.rows,
          pagination,
        });
    })
  }
  createHospital(){
    this.props.history.push('/privilege/user/create')
  }
  componentDidMount() {
    this.fetch({mer_id : CONFIG.S.get('mer_id')||''});
  }
	render(){
		const createHospital = this.createHospital.bind(this)
	return <div>
			<LayoutTitle name="管理员列表" opt={{btn : [{click:createHospital,text:'添加管理员'}]}} />
			<div style={{paddingTop:'8px'}}>
        <div style={{position:'relative'}}>
          <Search
            placeholder="输入管理名称或ID进行过滤"
            onSearch={value => console.log(value)}
            style={{ width: 400 }}
          />
        </div>
				<div style={{paddingTop:'8px'}}>
					{this.state.data.length ? <Table
				        columns={this.state.columns}
				        rowKey={record => record.id}
				        dataSource={this.state.data}
				        loading={this.state.loading}
				        onChange={this.handleTableChange}
                pagination={this.state.pagination}
				      />
          : '暂无数据'}
				</div>
			</div>
		</div>
	}
}

export default withRouter(User);