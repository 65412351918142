import React from "react";
import { Table, Divider, Tag,Input,Popconfirm  } from 'antd';
import LayoutTitle from '../../../components/layout/title';
import http from '../../../server';
import CONFIG from '../../../config';
import { withRouter } from "react-router";
const Search = Input.Search;
class HospitalList extends React.Component {
	constructor(props) {
   super(props);
    this.state = {
    data: [],
    pagination: {
    	pageSizeOptions : ['10','20','30','50','100'],
    	showSizeChanger : true
    },
    loading: false,
    
  };
}
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    if(filters.mer_name&&filters.mer_name.length){
      filters.mer_id = filters.mer_name.join(',')
    }
    this.setState({
      pagination: pager,
    });
    this.fetch({
      page_size: pagination.pageSize,
      page: pagination.current,
      sort: sorter.field ? sorter.field : '',
      order: sorter.order ? sorter.order.substr(0,sorter.order.length-3) : '',
      ...filters,
    });
  }

  //获取医院列表
  async fetch(config={}){
    let _this = this;
    await http.post('/Backend/hospital/find',config).then(function(data){
      data = data.data;
      const pager = { ..._this.state.pagination };
      pager.total = parseInt(data.total);
      _this.setState({
        pagination : pager,
        data : data.rows        
      })
    })
  }
  getMerchant(config={}){
    let _this = this;
    http.post('/Backend/merchant/find',config).then(function(data){
      data = data.data.rows;
      let filters = data.map((v) => {return {value : v.id,text:v.name}});
      _this.setState({
        filters : filters,
        columns : [
        {
          title: 'ID',
          dataIndex: 'id',
          sorter: true,
          width: '10%',
          render : (value, record) =>(<span style={{color:(!record.deleted_at ? 'rgba(0, 0, 0, 0.65)' : 'rgba(0, 0, 0, 0.3)')}}>{record.id}</span>)
        }, {
          title: '医院名称',
          dataIndex: 'name',
          sorter: true,
          width: '20%',
          render : (value, record) =>(<span style={{color:(!record.deleted_at ? 'rgba(0, 0, 0, 0.65)' : 'rgba(0, 0, 0, 0.3)')}}>{record.name}</span>)
        }, {
          title: '所属商户',
          dataIndex: 'mer_name',
          width: '20%',
          filters : filters,
          onFilter : (value, record)=>record.mer_id === value,
          render : (value, record) =>(<span style={{color:(!record.deleted_at ? 'rgba(0, 0, 0, 0.65)' : 'rgba(0, 0, 0, 0.3)')}}>{record.mer_name}</span>)
        }, {
          title: '联系人',
          dataIndex: 'contact',
          render : (value, record) =>(<span style={{color:(!record.deleted_at ? 'rgba(0, 0, 0, 0.65)' : 'rgba(0, 0, 0, 0.3)')}}>{record.contact+''+record.phone}</span>)
        }, {
          title: '省市',
          key: 'tags',
          dataIndex: 'province_name',
          render: (province_name,record) => (
            
              <span><Tag color="blue" key={'tag1'+record.id}>{province_name}</Tag>
              <Tag color="blue" key={'tag2'+record.id}>{record.city_name}</Tag>
          </span>
          ),
        }, {
          title: '操作',
          key: 'action',
          render: (text, record) => {
            let t = record.deleted_at||'';
            let text1 = t ? '上架' : '下架';
            let text2 = t ? '才能' : '将无法';
            let fun = t ? 'up' : 'delete';
            return (
              <span>
                <span onClick={_this.edit.bind(_this,text,record)} style={{color:'#1890ff',cursor:'pointer'}}>编辑</span>
                <Divider type="vertical" />
                <span onClick={_this.show.bind(_this,text,record)} style={{color:'#1890ff',cursor:'pointer'}}>详情</span>
                <Divider type="vertical" />
                <Popconfirm placement="topRight" title={'确认'+text1+'该医院吗?'+text1+'后，用户'+text2+'浏览相关信息'} onConfirm={_this[fun].bind(_this,text,record)} okText="确认" cancelText="取消">
                  <span style={{color:'#1890ff',cursor:'pointer'}}>{text1}</span>
                </Popconfirm>
              </span>
          )},
        }]
        
      })
    }).then(_this.fetch.bind(_this))
  }
  edit(text,record){
    this.props.history.push('/hospital/edit',record);
  }
  show(text,record){
    this.props.history.push('/hospital/edit',record);
  }
  up(text,record){
    let _this = this;
    http.post('/Backend/hospital/up',{ids:record.id}).then(function(data){
      _this.fetch()
    })
  }
  delete(text,record){
    let _this = this;
    http.post('/Backend/hospital/delete',{ids:record.id}).then(function(data){
      _this.fetch()
    })
  }
  createHospital(){
    this.props.history.push('/hospital/create');
  }
  search(value){
    if(!value.trim())return;
    this.fetch({
      keyword : value
    })
  }
  componentDidMount() {
    this.getMerchant({mer_id : CONFIG.S.get('mer_id')||''});
  }
	render(){
		const createHospital = this.createHospital.bind(this)
	return <div>
			<LayoutTitle name="医院列表" opt={{btn : [{click:createHospital,text:'添加医院'}]}} />
			<div style={{paddingTop:'8px'}}>
				<div style={{position:'relative'}}>
					<Search
      placeholder="输入医院名称或ID进行过滤"
      onSearch={value => this.search(value)}
      style={{ width: 400 }}
    />
				</div>
				<div style={{paddingTop:'8px'}}>
					<Table
				        columns={this.state.columns}
				        rowKey={record => record.id}
				        dataSource={this.state.data}
				        pagination={this.state.pagination}
				        loading={this.state.loading}
				        onChange={this.handleTableChange}
				      />
				</div>
			</div>
		</div>
	}
}

export default withRouter(HospitalList);