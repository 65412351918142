let CONFIG = {};
CONFIG.url = {
	base_host : '1xmb.cn',
	base_potocol : 'http',
	base_port : '80'
};
CONFIG.url.base_url = CONFIG.url.base_potocol + '://' +CONFIG.url.base_host + '/';
CONFIG.url.la_url = CONFIG.url.base_potocol + '://la.' +CONFIG.url.base_host + '/';
CONFIG.url.lc_url = CONFIG.url.base_potocol + '://lc.' +CONFIG.url.base_host + '/';
CONFIG.url.lh_url = CONFIG.url.base_potocol + '://lh.' +CONFIG.url.base_host + '/';
CONFIG.url.ls_url = CONFIG.url.base_potocol + '://ls.' +CONFIG.url.base_host + '/';
CONFIG.url.lr_url = CONFIG.url.base_potocol + '://lc.' +CONFIG.url.base_host;//静态资源路径
CONFIG.S = function(k,v){
	if(v === undefined){
		return this.get(k)
	}
	return this.set(k,v)
}
CONFIG.S.pre = 'lc_';
CONFIG.S.get = function(k){
	return window.localStorage.getItem(CONFIG.S.pre+k)
}
CONFIG.S.set = function(k,v){
	return window.localStorage.setItem(CONFIG.S.pre+k,v)
}
CONFIG.S.clear = function(){
	window.localStorage.clear();
}
CONFIG.dengji = [
[
	[{id:1,v:'院长'},{id:2,v:'副院长'}],
	[{id:3,v:'科主任'},{id:4,v:'科副主任'}],
	[{id:5,v:'护士长'}],
	[{id:6,v:'药房主任'},{id:7,v:'药库主任'}],
	[{id:8,v:'中药房主任'},{id:9,v:'中药库主任'}],
	[{id:10,v:'科主任'}],
	[{id:11,v:'科主任'}]
],
['医师','主任医师','副主任医师','主治医师','住院医师','医士'],
['护师','主任护师','副主任护师','主管护师','初级护师','初级护士'],
['药剂师','主任药剂师','副主任药剂师','主管药师','初级药师','初级药士'],
['中药剂师','主任药剂师','副主任药剂师','主管中药师','初级中药师','初级中药士'],
['检验师','主任检验师','副主任检验师','主管检验师','医学检验师','医学检验士'],
['技师','主任技师','副主任技师','主管技师','初级技师','初级技士']
];
CONFIG.dengjizhiwu = [{},{id:1,v:'院长'},{id:2,v:'副院长'},{id:3,v:'科主任'},{id:4,v:'科副主任'},{id:5,v:'护士长'},{id:6,v:'药房主任'},{id:7,v:'药库主任'},{id:8,v:'中药房主任'},{id:9,v:'中药库主任'},	{id:10,v:'科主任'},{id:11,v:'科主任'}];
CONFIG.color = [
'#f50','#2db7f5','#87d068','#108ee9','red','volcano','orange','gold','lime','green','cyan','blue','geekblue','purple'
];
export default CONFIG;