import React from "react";
import { Breadcrumb, Icon, Tooltip, message, Modal,Button } from 'antd';
import styles from './Schedule.css';
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
const img1 = require('../../assets/img/pk_loading.png');
const confirm = Modal.confirm;

class Setting_select extends React.Component {
    constructor(props) {
        console.log(props)
        super(props)
        this.state = {};
    }
    lockSelect = () => {
        const {history} = this.props;
        confirm({
            title: '请确定是否锁定选课',
            content: '锁定后，选课链接暂时关闭，本次排课进入选课统计阶段。',
            okText: '确定',
            cancelText: '返回',
            onOk() {

                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.01 ? () => {
                        history.replace('/schedule/selectResult');
                        resolve();
                    } : reject, 1000);
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel() {},
        });
    }
    cancelSelect = () => {
        const {history} = this.props;
        confirm({
            title: '请确定是否取消选课',
            content: '若确定取消，则本次选课无效。',
            okText: '确定',
            cancelText: '返回',
            onOk() {

                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.01 ? () => {
                        history.replace('/schedule');
                        resolve();
                    } : reject, 1000);
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel() {},
        });
    }
    copyUrl = () => {
        message.info('已成功复制链接到剪切板');
    }
    render() {
        return (
            <div>
        <div className={styles.breadcrumb}>
            <Breadcrumb>
    <Breadcrumb.Item>
      <Icon type="laptop" />
    </Breadcrumb.Item>
    <Breadcrumb.Item>
    <Link to='/schedule'>
      排课管理</Link>
    </Breadcrumb.Item>
     <Breadcrumb.Item>
      <Link to='/schedule/create'>
      新建排课</Link>
    </Breadcrumb.Item>
    <Breadcrumb.Item>
      选课详情
    </Breadcrumb.Item>
  </Breadcrumb></div>
     <div className={styles.strategyContent}>
     <div className={styles.strategyTable}>
 <div className={styles.selectProgress}>
  
 
 <div className={styles.progressUpSide}>
             <img src={img1} style={{
                width: '20vh',
                height: '20vh',
                marginBottom: 24
            }} alt="img1"/>
          <span style={{
                color: 'rgba(0,0,0,.85)',
                fontSize: 20,
            }}>正在选课</span>
            </div>
            
            <div className={styles.selectProgressAnimate} style={{
                opacity: 1,
                transform: 'translate(0px, 0px)'
            }}><div className={styles.animate} style={{
                transform: 'translateX(-64px)'
            }}></div></div>
            <div className={styles.progressDownSide}>
               <span style={{
                color: 'rgba(0,0,0,.85)',
                fontSize: 16,
                marginBottom: 24
            }}>选课链接已创建，请<Button onClick={this.copyUrl}>复制</Button>并分享链接{ /*如果已选人数大于1则显示文本：n人已选，剩余m人*/ }</span>
             <div className={styles.footerButton}>  <Tooltip title="访问选课页面"> <Icon type="link" className={styles.tableSettingIcon} /></Tooltip><Tooltip title="未选课学生列表"><Icon type="team" className={styles.tableSettingIcon}/></Tooltip><Tooltip title="锁定选课"> <Icon type="lock" className={styles.tableSettingIcon} onClick={this.lockSelect}/></Tooltip><Tooltip title="取消选课">  <Icon type="close" className={styles.tableSettingIcon} onClick={this.cancelSelect}/></Tooltip> </div>
            </div>
           
</div>

            </div>
           
          
           </div>
         
           
            </div>
        );
    }
}

export default withRouter(Setting_select);