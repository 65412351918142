import React from "react";
import { Button, Breadcrumb, Icon, Select, Col, Row, Popover, Checkbox, Input } from 'antd';
import styles from './Schedule.css';
import { Link } from "react-router-dom";
import { Pie, Bar } from 'ant-design-pro/lib/Charts';
const Option = Select.Option;
const CheckboxGroup = Checkbox.Group;
const sexyData = [{
    x: '男',
    y: 560,
}, {
    x: '女',
    y: 720,
}];
const scoreData = [{
    x: '500分以下',
    y: 85
}, {
    x: '500-550分',
    y: 120
}, {
    x: '550-600分',
    y: 240
}, {
    x: '600-650分',
    y: 580
}, {
    x: '650分以上',
    y: 255
}]
const courseData = [{
    x: '语文',
    y: 40
}, {
    x: '数学',
    y: 40
}, {
    x: '英语',
    y: 40
}, {
    x: '物理',
    y: 40
}, {
    x: '化学',
    y: 40
}, {
    x: '生物',
    y: 40
}, {
    x: '体育',
    y: 40
}, {
    x: '信息技术',
    y: 40
}, {
    x: '音乐',
    y: 40
}, {
    x: '历史',
    y: 40
}, {
    x: '政治',
    y: 40
}, {
    x: '地理',
    y: 40
}]
const roomData = [{
    x: '西区一教',
    y: 40
}, {
    x: '西区操场',
    y: 5
}, {
    x: '音乐教室',
    y: 5
}, {
    x: '机房',
    y: 5
}, {
    x: '多媒体教室',
    y: 5
}];
const plainOptions = ['语文', '数学', '英语',
    '物理', '化学', '生物', '体育', '政治', '历史', '地理'
];
// const options = [{
//     label: '语文',
//     value: '语文'
// }, {
//     label: '数学',
//     value: '数学'
// }, {
//     label: '英语',
//     value: '英语'
// }, {
//     label: '物理',
//     value: '物理'
// }, {
//     label: '化学',
//     value: '化学'
// }, {
//     label: '生物',
//     value: '生物'
// }, {
//     label: '体育',
//     value: '体育'
// }, {
//     label: '政治',
//     value: '政治'
// }, {
//     label: '历史',
//     value: '历史'
// }, {
//     label: '地理',
//     value: '地理'
// }

// ];


class Setting_noSelect extends React.Component {
    onChange = (checkedValues) => {
        console.log('checked = ', checkedValues);
    }
    render() {
        const content = (
        <div>
   <CheckboxGroup options={plainOptions} defaultValue={['语文', '数学', '英语',
            '物理', '化学', '生物', '体育', '政治', '历史', '地理'
        ]} onChange={this.onChange} style={{
            display: 'flex',
            flexWrap: 'wrap',
            width: '204px',
            justifyContent: 'space-between'
        }}/>
  </div>
        );
        return (
            <div>
        <div className={styles.breadcrumb}>
            <Breadcrumb>
    <Breadcrumb.Item>
      <Icon type="laptop" />
    </Breadcrumb.Item>
    <Breadcrumb.Item>
    <Link to='/schedule'>
      排课管理</Link>
    </Breadcrumb.Item>
     <Breadcrumb.Item>
      <Link to='/schedule/create'>
      新建排课</Link>
    </Breadcrumb.Item>
    <Breadcrumb.Item>
      排课配置
    </Breadcrumb.Item>
  </Breadcrumb></div>
     <div className={styles.strategyContent}>
     <Row>
     <Col span={8}>
           <div className={styles.strategyTable} style={{
                height: 360
            }}>
           <div className={styles.strategyTableTitle}>
           <div style={{
                color: '#949494',
                fontSize: 14
            }}>2020届总人数</div>
           <div style={{
                fontSize: 30,
                fontWeight: 500
            }}>{sexyData[0].y + sexyData[1].y}</div>
           </div>
            <Pie
            colors={['rgba(24, 144, 255, 0.85)', 'rgba(214, 52, 83, 0.85)']}
            data={sexyData}

            height={200}
            />
            <div className={styles.strategyTableFooter}> <span className={styles.strategyMale}>男{' ' + sexyData[0].y}人</span><span className={styles.strategyFemale}>女{' ' + sexyData[1].y}人</span></div>
         
  
           </div>
           </Col>
            <Col span={16} style={{
                paddingLeft: 16
            }}>
           <div className={styles.strategyTable}  style={{
                height: 360
            }}>
            <div className={styles.tableSetting}><Icon type="setting" className={styles.tableSettingIcon}/></div>
          <Bar
            height={310}
            title="2020届总成绩分布"
            data={scoreData}
            />
  
           </div>
           </Col>
         
             	 <Col span={24}>
             	 <div className={styles.strategyTable}>
             	 <div className={styles.strategyTableTitle}>
           <div style={{
                color: '#949494',
                fontSize: 14
            }}>高一课程安排</div>
           </div>
           <div className={styles.strategyTableCustom}>
           <div className={styles.first}>
           <div className={styles.tableBlock}>课程</div>
           <div className={styles.tableBlock}>课时</div>
           </div>
           {courseData.map(data => <div className={styles.other}>
           <div className={styles.tableBlock}>{data.x}</div>
           <div className={styles.tableBlock}>{data.y}</div>
           </div>
            )}
           </div>
             	 </div>
             	 </Col>
             	  <Col span={24}>
             	 <div className={styles.strategyTable}>
             	 <div className={styles.strategyTableTitle}>
           <div style={{
                color: '#949494',
                fontSize: 14
            }}>高一教室资源</div>
           </div>
           <div className={styles.strategyTableCustom}>
           <div className={styles.first}>
           <div className={styles.tableBlock}>名称</div>
           <div className={styles.tableBlock}>数量</div>
           </div>
           {roomData.map(data => <div className={styles.other}>
           <div className={styles.tableBlock}>{data.x}</div>
           <div className={styles.tableBlock}>{data.y}</div>
           </div>
            )}
           </div>
             	 </div>
             	 </Col>
             	 <Col span={24}>
             	 <div className={styles.strategyTable}>
             	 <div className={styles.strategyTableTitle}>
           <div style={{
                color: '#949494',
                fontSize: 14
            }}>班级配置</div>
           </div>
           <div className={styles.strategyClass}>
        <Popover content={content} title="请勾选计入成绩排名的科目">
         <div className={styles.strategyRules}>排名规则设置</div></Popover>
         </div>
         <div className={styles.strategyClassTable}>
          <div className={styles.strategyClassTableRowHead}>
            <div className={styles.strategyClassTableRowBlock}>
            班级类型
            </div>
              <div className={styles.strategyClassTableRowBlock}>
            班级数量
            </div>
              <div className={styles.strategyClassTableRowBlock}>
            学生范围（按排名）
            </div>
              <div className={styles.strategyClassTableRowBlock}>
            排班规则
            </div>
             <div className={styles.strategyClassTableRowBlock}>
            操作
            </div>
           </div>
           <div className={styles.strategyClassTableRow}>
            <div className={styles.strategyClassTableRowBlock}>
            <Input placeholder="班级名称（如：普通班）" />
            </div>
              <div className={styles.strategyClassTableRowBlock}>
            <Input placeholder="班级数量" />
            </div>
              <div className={styles.strategyClassTableRowBlock}>
            <Button type="default" size="default" block> 选择</Button>
            </div>
              <div className={styles.strategyClassTableRowBlock}>
            <Select defaultValue="请选择" style={{
                width: '100%'
            }}>
      <Option value="按排名顺序分班">按排名顺序分班</Option>
      <Option value="按平均成绩分班">按平均成绩分班</Option>
 
    </Select>
            </div>
             <div className={styles.strategyClassTableRowBlock}>
            <Button type="primary" size="default" > 保存</Button>
            </div>
           </div>
         </div>
             	 </div>
             	 </Col>
             </Row>
           <div className={styles.createFooter}><Link to="/schedule/strategy"><Button type="primary" size="large">开始排课</Button></Link></div>
          
           </div>
         
           
            </div>
        );
    }
}

export default Setting_noSelect;