import axios from 'axios'
import qs from 'qs'
import CONFIG from './config';
let http={
	post : '',
	get : ''
}
http.post = function(api,data){
	let params = qs.stringify(data)
	return new Promise((resolve,reject) =>{
		axios.post(CONFIG.url.lc_url+api,params).then((res) => {
			resolve(res)
		})
	})
}

http.get = function(api,data){
	let params = qs.stringify(data)
	return new Promise((resolve,reject) =>{
		axios.get(CONFIG.url.lc_url+api,params).then((res) => {
			resolve(res)
		})
	})
}
export default http