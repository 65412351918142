import React from "react";
import {   Button, Breadcrumb, Icon, Select } from 'antd';
import styles from './Schedule.css';
import { Link } from "react-router-dom";

const Option = Select.Option;
const img1 = require('../../assets/img/pk_create_1.png');
const img2 = require('../../assets/img/pk_create_2.png');
const img3 = require('../../assets/img/pk_create_4.png');
class Create extends React.Component {
    constructor(props) {

        super(props)
        this.state = {
            course: '请选择',
            students: '请选择',
            select: '请选择',
            router: false,
        };
    }
    onChange = n => e => {
        this.setState({
            [n]: e
        }, () => {
            if (this.state.course !== '请选择' && this.state.students !== '请选择' && this.state.select === '开启') {
                this.setState({
                    router: 'setting_select'
                }, () => console.log(this.state))
            } else if (this.state.course !== '请选择' && this.state.students !== '请选择' && this.state.select === '不开启') {
                this.setState({
                    router: 'setting_noSelect'
                }, () => console.log(this.state))
            }
        });
    }
    render() {
        return (
            <div>
        <div className={styles.breadcrumb}>
            <Breadcrumb>
    <Breadcrumb.Item>
      <Icon type="laptop" />
    </Breadcrumb.Item>
    <Breadcrumb.Item>
    <Link to='/schedule'>
      排课管理</Link>
    </Breadcrumb.Item>
     <Breadcrumb.Item>
      新建排课
    </Breadcrumb.Item>
  </Breadcrumb></div>
     <div className={styles.createContent}>
           <div className={styles.createTable}>
            <div className={styles.createColumn }>
<div className={styles.cloumnTitle}><h3>选择学生</h3></div>
           <img className={styles.createImg} src={img2} alt="2"/>
           <div className={styles.columnContent}>
           <div className={styles.columnSpan}><span>请选择需要进行排课的学生，如果没有录入，请前往“资源录入-学生管理”录入。</span></div>
           <Select defaultValue="请选择" style={{
                width: 120
            }} onChange={this.onChange('students')}>
      <Option value="2020届">2020届</Option>
      <Option value="2021届">2021届</Option>
      <Option value="2022届">2022届</Option>
    </Select></div>
           </div>
           <div className={styles.createColumn + ' ' + styles.divider}>
<div className={styles.cloumnTitle}><h3>选择课程</h3></div>
           <img className={styles.createImg} src={img1} alt="1"/>
           <div className={styles.columnContent}><div className={styles.columnSpan}><span>请选择需要排课的年级课程，如果没有录入课程，请前往“资源录入-课程管理”录入对应年级的课程。</span></div>
           <Select defaultValue="请先选择学生" style={{
                width: 120
            }} onChange={this.onChange('course')}>

      <Option value="高一">高一</Option>
      <Option value="高二">高二</Option>
      <Option value="高三">高三</Option>
      <Option value="高一快班">高一快班</Option>
    </Select></div>
           </div>
           
             <div className={styles.createColumn + ' ' + styles.divider}>
<div className={styles.cloumnTitle}><h3>开启选课</h3></div>
           <img className={styles.createImg} src={img3} alt="3"/>
           <div className={styles.columnContent}>
           <div className={styles.columnSpan}><span>请选择是否开启选课功能，如开启，则需要学生前往指定链接地址进行选课。</span></div>
           <Select defaultValue="请选择" style={{
                width: 120
            }} onChange={this.onChange('select')}>
      <Option value="开启">开启</Option>
      <Option value="不开启">不开启</Option>
    </Select></div>
           </div>
           </div>
           <div className={styles.createFooter}><Link to={'/schedule/' + this.state.router}><Button type="primary" size="large" disabled={!this.state.router}>下一步</Button></Link></div>
          
           </div>
         
           
            </div>
        );
    }
}

export default Create;