import React from "react";
import {  Button, message,Form, Input, Row, Col,Radio,Upload,Icon,Select,Tag,Tooltip  } from 'antd';
import http from '../../../server';
import { withRouter } from "react-router";
import CONFIG from '../../../config';
const { TextArea } = Input;
const FormItem = Form.Item;
const Option = Select.Option;
const RadioGroup = Radio.Group;
const dengji = CONFIG.dengji;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 7 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
};
let uploadOpt = {
  accept : 'image/gif, image/jpeg, image/jpg, image/png, image/bmp',
  action: CONFIG.url.lc_url+'Backend/backend/upload',
  listType: 'picture',
  defaultFileList: [],
  disabled : false,//是否禁用
  multiple : false,//多选文件
  withCredentials : true,
  className: 'upload-list-inline'
};
var upload = {
  head_url : [],
  uploadhead_url : {
    ...uploadOpt,
    multiple : true,//多选文件
    onChange : changeUpload.bind(this,'head_url',5),
    onRemove : removeUpload.bind(this,'head_url',5)
  },
  head_avatar : [],
  uploadhead_avatar : {
    ...uploadOpt,
    onChange : changeUpload.bind(this,'head_avatar',1),
    onRemove : removeUpload.bind(this,'head_avatar',1)
  }
};
  //上传图片
  function changeUpload(type,len,e){
    if(e.file.status === 'done'){
      //上完完成
      if(e.file.response.code === 0){
        //上传成功
        upload[type] = e.fileList;//e.file.response.data.save_path,
        upload['upload'+type].disabled=(e.fileList.length >= len ? true : false)
            //defaultFileList : e.fileList
          
      }
    }
  }
  //删除图片
  function removeUpload(type,len,e){
    if(e.status === 'removed'){
      let arr = upload[type].filter(o => o.uid !== e.uid);
      upload[type] = arr;//e.file.response.data.save_path,
      upload['upload'+type].disabled=(arr.length >= len ? true : false)
        //defaultFileList:arr
            
    }    
  }
  function getUploadPath(arr){
    if(!arr.length)return '';
    for(let i = 0;i<arr.length;i++){arr[i] = arr[i].response.data.save_path;}
    return arr.join(',');
  }

var selectData=[];

class CreateDepartment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {      
      btnloading : false,
      tags : [],
      taginputVisible : false,
      taginputValue : '',
      doc_type : 1,
      doc_title : 1,
      h_id: props.location && props.location.state && props.location.state.h_id && props.location.state.h_id!== '0' ? props.location.state.h_id : undefined,
      dep_id : props.location && props.location.state && props.location.state.dep_id && props.location.state.dep_id!== '0' ? props.location.state.dep_id : undefined,
      deptSelectData : [],
      dep_name : '',
      doc_duty : undefined,
      status : 1,
      doc_phone : '',
      gender : '1',
      treeSelectData : [],
      sort : 99,
    };
  }
  componentDidMount(){    
    this.fetch({mer_id : CONFIG.S.get('mer_id')||''});
  }
  componentWillUnmount = () => {
    this.setState = (state,callback)=>{
      return;
    };
  }
//获取医院列表
async fetch(params={}){
    let _this = this;
    await http.post('/Backend/hospital/find',params).then(function(data){
      data = data.data;
      selectData = data.rows||[];
      let o = {};
      o.treeSelectData = selectData;
      _this.setState(o)
    }).then(_this.fetchDepart.bind(_this,{}))
  }
//获取科室

async fetchDepart(params = {}){
    let _this = this;
    if(!_this.state.h_id && !params.h_id)return;
    await http.post('/Backend/depart/find',{h_id : _this.state.h_id||params.h_id}).then(function(data){
      data = data.data;
      _this.setState({
        deptSelectData: data.rows
      });
    })
  }
//选择医院
  onChange = (h_id) => {
    let _this = this;
    for(let i = 0;i<selectData.length;i++){
      if(selectData[i].id === h_id){
        this.setState({ h_id:h_id},()=>_this.fetchDepart({ h_id:h_id}));
        return;
      }
    }    
  }
  //科室
  onChangePid = (p_id) => {
    this.setState({
      dep_id : p_id
    });
  }
  onChangeInput = prop => e => {
      this.setState({
          [prop]: e.target.value.trim()
      });
  }
  onChangeRadio = prop => e => {
    let o = {};
    o[prop] = e.target.value;
    if(prop === 'doc_type' && this.state.doc_duty !== 1 && this.state.doc_duty !== 2){
      o.doc_duty = undefined;
    }
      this.setState(o);
  }
  //回车事件
  KeyUpHandle(e){
      if (e.keyCode !== 13) {
          return;
      }
      this.submit();
  }
  //提交
  submit(){
    let _this = this;
    let o = {
      h_id : '',
      doc_name : '',
      doc_phone : '',
      doc_type : '',
      dep_id : '',
      gender : '',
      doc_title : '',
      doc_intr : '',
      doc_point : '',
      sort : '',
      doc_exp : '',
      status : '',
      doc_duty:''
    };
    for(let k in o){o[k] = _this.state[k]}
    o.head_url = getUploadPath(upload.head_url); 
    o.head_avatar = getUploadPath(upload.head_avatar);
    o.doc_tag = _this.state.tags.join(',');
    if(!o.doc_name || o.doc_name.length < 2 || o.doc_name.length > 40){
            message.error('请输入职工姓名');
            return;
        }
        if(!o.head_avatar){
            message.error('请上传头像');
            return;
        }
        if(!o.h_id || o.h_id === '0'){
          message.error('请选择所属医院');
          return;
        }
        if(!o.dep_id || o.dep_id === '0'){
          message.error('请选择所属科室');
          return;
        }

        if(!o.doc_type || o.doc_type === '0'){
          message.error('请选择职业');
          return;
        }
        if(!o.doc_title || o.doc_title === '0'){
          message.error('请选择职称');
          return;
        }
        if(!o.head_url || o.head_url === ''){
          message.error('请上传职工介绍顶部的banner图');
          return;
        }
        if(!o.doc_intr || o.doc_intr.length < 2){
          message.error('请输入简介');
          return;
        }
        if(!o.doc_tag || o.doc_tag.length < 2){
          message.error('请添加擅长标签');
          return;
        }
        // this.setState({
        //     btnloading: true
        // });
        
        http.post('/Backend/doctor/create',o).then((data) =>{
          data = data.data;
          if(data.code === 0){
            message.success(data.msg);              
            _this.props.history.go(-1);
          }else{
            message.error(data.msg);
            _this.setState({
                btnloading: false
            });
          }
        });
        
        
  }
  //Tag
  handleClose = (removedTag) => {
    const tags = this.state.tags.filter(tag => tag !== removedTag);
    this.setState({ tags });
  }

  showInput = () => {
    this.setState({ taginputVisible: true }, () => this.input.focus());
  }

  handleInputChange = (e) => {
    this.setState({ taginputValue: e.target.value });
  }

  handleInputConfirm = () => {
    const state = this.state;
    const taginputValue = state.taginputValue;
    let tags = state.tags;
    if (taginputValue && tags.indexOf(taginputValue) === -1) {
      tags = [...tags, taginputValue];
    }
    this.setState({
      tags,
      taginputVisible: false,
      taginputValue: '',
    });
  }

  saveInputRef = input => this.input = input
  render() {
    const {btnloading,tags, taginputVisible, taginputValue}=this.state;
    return (
      <div>
        <div className="steps-content">
          <div style={{padding:'24px 30px'}}>
      <Form>
    <FormItem
      {...formItemLayout}
      label="姓名"
      validateStatus=""
      help=""
    >
      <Input placeholder="请输入职工姓名" value={this.state.doc_name} id="doc_name" 
            onKeyUp={this.KeyUpHandle.bind(this)} maxLength={40}
            onChange={this.onChangeInput('doc_name')}/>
    </FormItem>    
    <FormItem
      {...formItemLayout}
      label="电话"
      validateStatus=""
      help=""
    >
      <Input placeholder="请输入联系电话" value={this.state.doc_phone} id="doc_phone" 
            onKeyUp={this.KeyUpHandle.bind(this)} maxLength={12}
            onChange={this.onChangeInput('doc_phone')}/>
    </FormItem>
    <FormItem
      {...formItemLayout}
      label="医院"
      hasFeedback
      validateStatus=""
      help=""
    >
      <Select
        style={{ width: '100%'}}
        value={this.state.h_id}
        placeholder="请选择所属医院"
        onChange={this.onChange}>
        {
          this.state.treeSelectData.length && this.state.treeSelectData.map((k,v)=>(
            <Option value={k.id} key={k.id}>{k.name}</Option>
            ))
        }
      </Select>
    </FormItem>

    <FormItem
      {...formItemLayout}
      label="科室"
      hasFeedback
      validateStatus=""
      help=""
    >
      <Select
        style={{ width: '100%'}}
        value={this.state.dep_id}
        placeholder="请选择所属科室"
        notFoundContent="请先选择所属医院"
        onChange={this.onChangePid.bind(this)}>
        {
          this.state.deptSelectData.length && this.state.deptSelectData.map((k,v)=>(
            <Option value={k.id} key={k.id}>{k.dep_name}</Option>
            ))
        }
      </Select>
    </FormItem>
    <FormItem
        {...formItemLayout}
        label="职业"
        hasFeedback
        validateStatus=""
      >
        <RadioGroup onChange={this.onChangeRadio('doc_type')} value={this.state.doc_type}>
          {
            dengji.map((v,i)=>{
                if(i!==0){
                  return (<Radio value={i} key={i}>{v[0]}</Radio>)
                }
                return ''
              }
                
            )
          }
        </RadioGroup>
      </FormItem>

      <FormItem
        {...formItemLayout}
        label="职称"
        hasFeedback
        validateStatus=""
      >
        <RadioGroup onChange={this.onChangeRadio('doc_title')} value={this.state.doc_title}>
          {
            dengji[this.state.doc_type].map((v,i)=>{
              if(i !== 0){
                return <Radio value={i} key={i}>{v}</Radio>
              }
                return ''
            })
          }
        </RadioGroup>
      </FormItem>
    <FormItem
        {...formItemLayout}
        label="职务"
        hasFeedback
        validateStatus=""
      >
      <div>
      <RadioGroup onChange={this.onChangeRadio('doc_duty')} value={this.state.doc_duty}>
          {
            dengji[0][0].map((v,i)=>(<Radio value={v.id} key={i}>{v.v}</Radio>))}{
            dengji[0][this.state.doc_type].map((v,i)=>(<Radio value={v.id} key={i}>{v.v}</Radio>))
          }
        </RadioGroup>
        </div>
      </FormItem>
    <FormItem
        {...formItemLayout}
        label="性别"
        hasFeedback
        validateStatus=""
      >
        <RadioGroup onChange={this.onChangeRadio('gender')} value={this.state.gender}>          
              <Radio value='1'>男</Radio><Radio value='2'>女</Radio><Radio value='3'>未知</Radio>
        </RadioGroup>
      </FormItem>



    <FormItem
        {...formItemLayout}
        label="头像"
        validateStatus=""
      >
        <Upload {...upload.uploadhead_avatar} key="uploaddeptbanner2">
          <Button>
            <Icon type="upload" key="2"/> 400*400等比率
          </Button>
        </Upload>
      </FormItem>
    <FormItem
        {...formItemLayout}
        label="banner"
        validateStatus=""
        help="职工介绍顶部的banner图"
      >
        <Upload {...upload.uploadhead_url} key="uploaddeptbanner1">
          <Button>
            <Icon type="upload" key="1"/> 750*290等比率
          </Button>
        </Upload>
      </FormItem>

      <FormItem
      {...formItemLayout}
      label="标签"
      validateStatus=""
      help="擅长的疾病名称,或精通某领域。如：糖尿病"
    >
      <div>{tags.map((tag, index) => {
          const isLongTag = tag.length > 20;
          const tagElem = (
            <Tag color={CONFIG.color[index]} key={tag} closable={true} afterClose={() => this.handleClose(tag)}>
              {isLongTag ? `${tag.slice(0, 20)}...` : tag}
            </Tag>
          );
          return isLongTag ? <Tooltip title={tag} key={tag}>{tagElem}</Tooltip> : tagElem;
        })}
        {taginputVisible && (
          <Input
            ref={this.saveInputRef}
            type="text"
            size="small"
            style={{ width: 78 }}
            value={taginputValue}
            onChange={this.handleInputChange}
            onBlur={this.handleInputConfirm}
            onPressEnter={this.handleInputConfirm}
          />
        )}
        {!taginputVisible && (
          <Tag
            onClick={this.showInput}
            style={{ background: '#fff', borderStyle: 'dashed' }}
          >
            <Icon type="plus" /> 添加
          </Tag>
        )}
        </div>
    </FormItem>

    <FormItem
      {...formItemLayout}
      label="简介"
      validateStatus=""
      help=""
    >
      <TextArea placeholder="简介,2000字以内" autosize value={this.state.doc_intr} id="doc_intr" 
            onKeyUp={this.KeyUpHandle.bind(this)} maxLength={2000}
            onChange={this.onChangeInput('doc_intr')}/>
    </FormItem>
    <FormItem
      {...formItemLayout}
      label="擅长"
      validateStatus=""
      help=""
    >
      <TextArea placeholder="擅长,2000字以内" autosize value={this.state.doc_point} id="doc_point" 
            onKeyUp={this.KeyUpHandle.bind(this)} maxLength={2000}
            onChange={this.onChangeInput('doc_point')}/>
    </FormItem>
    <FormItem
      {...formItemLayout}
      label="经历"
      validateStatus=""
      help=""
    >
      <TextArea placeholder="受教育经历,培训,荣誉等,2000字以内" autosize value={this.state.doc_exp} id="doc_exp" 
            onKeyUp={this.KeyUpHandle.bind(this)} maxLength={2000}
            onChange={this.onChangeInput('doc_exp')}/>
    </FormItem>
    
    <FormItem
      {...formItemLayout}
      label="排序"
      validateStatus=""
      help=""
    >
      <Input placeholder="排列顺序" value={this.state.sort} id="sort" 
            onKeyUp={this.KeyUpHandle.bind(this)} maxLength={40}
            onChange={this.onChangeInput('sort')}/>
    </FormItem>


  </Form>

    </div>
        </div>
        <div className="steps-action" style={{textAlign:'left',paddingBottom:'50px'}}>
        <Row gutter={48}>
        <Col className="gutter-row" span={7}></Col>
          <Col className="gutter-row" span={10}>
          <Button type="primary" loading={btnloading} onClick={this.submit.bind(this)}>{btnloading ? '提交中' : '提交'}</Button>          
          </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withRouter(CreateDepartment)