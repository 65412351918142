import { Layout, Menu, Icon, Dropdown, message, Button } from 'antd';
import React from "react";
import basic from './basic.css';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Route, Redirect, Link } from "react-router-dom";
import { withRouter } from "react-router";
import CONFIG from '../../config';

const SubMenu = Menu.SubMenu;
const logo = require('../../assets/img/logo.jpg')

const {Header, Sider, Content} = Layout;

const styles = theme => ({
    logoImg: {
        width: 30,
        height: 30
    },
    logo: {
        height: '64px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: '30px'

    },
    header: {
        boxShadow: ' 0 2px 8px #f0f1f2',
        background: '#fff',
        zIndex: 10,
        padding: '0',
        display: 'flex'
    },
    headerContent: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '0 15px',
        justifyContent: 'space-between',
        position: 'relative'
    },
    headerButtonTab: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'absolute',
        padding: '0 30px',
        right: 0,
    }
});



class Basic extends React.Component {
    state = {
        collapsed: false,
        sys_info : {
            mer_name : '',
            mer_logo : '',
            role_name : '',
            real_name : '',
            mer_id : '',
            user_id : ''
        },
    };
    logoTitleRemove = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        })
    }
    componentDidMount(){
      let sys_info = {...this.state.sys_info};
      for(let k in sys_info){
        sys_info[k] = CONFIG.S.get(k);
      }
      if(!sys_info.user_id){
        this.props.history.replace('/user/login');
      }
      sys_info.mer_logo = sys_info.mer_logo ? (CONFIG.url.lc_url + sys_info.mer_logo) : logo;
      this.setState({sys_info})
    }
    clickMenu({key}) {
        if(key === '退出登录'){
            CONFIG.S.clear();
            message.info(`已 ${key}`);
            this.props.history.replace('/user/login');
        }
        
    }
    render() {
        const {classes, routes} = this.props
        const dropDown = (
            <Menu onClick={this.clickMenu.bind(this)}>
                <Menu.Item key='账号设置'>
                  <Link rel="noopener noreferrer" to="#">账号设置</Link>
                </Menu.Item>
                <Menu.Item key='使用帮助'>
                  <Link  rel="noopener noreferrer" to="#">使用帮助</Link>
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item key='退出登录'>
                  <Link rel="noopener noreferrer" to="#">退出登录</Link>
                </Menu.Item>
              </Menu>
            );
        //获取当前默认菜单
        const current = this.props.location.pathname;
        let defaultSelectedKeys,defaultOpenKeys;
        b:for(let i = 0;i<routes.length;i++){
            if(routes[i].path === current){
                defaultOpenKeys = routes[i].menu;
                break;
            }
            if(routes[i].routes && routes[i].routes.length){
                for(let j = 0;j<routes[i].routes.length;j++){
                    if(routes[i].routes[j].path === current){
                        defaultOpenKeys = routes[i].routes[j].menu;
                        break b;
                    }
                }
            }
        }
        defaultOpenKeys = defaultOpenKeys ? [defaultOpenKeys] : ['/'];
        defaultSelectedKeys = ['/'+current.split('/')[1]];
        return (
            <Layout>
              <Header className={classes.header}>
              <div className={basic.logoBlock}>
                <div className={classes.logo}>
<img className={classes.logoImg} src={this.state.sys_info.mer_logo} alt="logo"/>
 <Typography className = {
            basic.logoTitle
            }
            variant = "h6" >
          医院后台管理
        </Typography>
        </div>
          </div>
          <div className={classes.headerContent}>
         <span className={basic.navTitle}>{this.state.sys_info.mer_name}</span><div className={classes.headerButtonTab}><span style={{marginRight:'10px'}}>{this.state.sys_info.role_name+' '+this.state.sys_info.real_name}</span><Dropdown overlay={dropDown} trigger={['click']}>
    
      <div><Button type="primary" shape="circle" className='navIcon'><Icon type="user" style={{
                fontSize: '18px',
                color: '#fff'
            }}/></Button></div>
   
  </Dropdown></div>
          </div>
           
          </Header>
          <Layout style={{
                paddingTop: 40,
                background: '#fff'
            }}>
        <Sider
            breakpoint='lg'
            style={{
                userSelect: 'none',
                maxWidth: '240px'
            }}
            theme="light"
            width='18%'
            onBreakpoint={this.logoTitleRemove}
            >
      
          <Menu theme="light" mode="inline" defaultSelectedKeys={defaultOpenKeys} defaultOpenKeys={defaultSelectedKeys}>
          {
            routes.map(routes => {
                if (!routes.redirect) {
                    if (routes.component) {
                        if (!routes.hide) {
                            return <Menu.Item key={routes.path}><Link to={routes.path}>
                                        <Icon type={routes.icon} />
                                            <span>{routes.name}</span></Link>
                                    </Menu.Item>
                        }

                    } else {
                        const li = routes.routes.map(list => 
                            <Menu.Item key={list.path}>
                                <Link to={list.path}>
                                    <span>{list.name}</span>
                                </Link>
                            </Menu.Item>)
                        return <SubMenu key={routes.path} title={<span><Icon type={routes.icon} /><span>{routes.name}</span></span>}>{li}</SubMenu>
                    }
                }
                return '';
            })
            }
          </Menu>
        </Sider>
        <Layout style={{
                background: '#fff'
            }}>
        
          <Content className={basic.basicContent}>
        
            {routes.map((routes, i) => {


                if (!routes.redirect) {
                    const list = routes.routes
                    if (list) {
                        const arr = list.map(list => <Route exact key={list.path} path={list.path} render={() => <list.component />}/>)
                        return arr;
                    } else {
                        return <Route exact key={routes.path} path={routes.path} render={() => <routes.component />}/>
                    }

                } else {

                    return <Route exact key={routes.path} path={routes.path} render={() => <Redirect to={routes.redirect} />}/>
                }
            })}

          </Content>
           </Layout>
        </Layout>
      </Layout>
        );
    }
}

export default withStyles(styles)(withRouter(Basic));