import React from "react";
import {  Button, message,Form, Input, Row, Col, TreeSelect , Radio  } from 'antd';
import CONFIG from '../../../config';
import reqwest from 'reqwest';
import { withRouter } from "react-router";
const RadioGroup = Radio.Group;
const FormItem = Form.Item;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 7 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
};

var selectData=[];

class CreateHospital extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showXingzhi : true,
      loading : false,
      value: undefined,
      show : 1,
      name : '',
      icon : '',
      status : 1,
      sort : 99,
      url : '',
      treeSelectData : [],
    };
  }
  componentDidMount(){    
    this.fetch();
  }

createSelect(pid){
  let temp = [];
  for(let i = 0;i<selectData.length;i++){
    if(selectData[i].pid===pid){
      temp.push({
        title : selectData[i].name,
        sort : selectData[i].sort,
        value : selectData[i].id,
        key : selectData[i].pid+'-'+selectData[i].id,
        children : [],
        ...selectData[i]
      });
    }
  }
  if(temp.length){
    temp = temp.sort(function(a,b){
      return a.sort > b.sort ? 1 : -1
    });
    for(let j = 0;j<temp.length;j++){
      temp[j].children=this.createSelect(temp[j].id);
    }
  }
  return temp;
}
  fetch(){
    let _this = this;
    reqwest({
          url: CONFIG.url.lc_url+'Backend/sysFunc/find',
          method: 'post',
          contentType: 'application/x-www-form-urlencoded',
          crossOrigin: true,
          withCredentials: true,
          data: {},
          type: 'json',
        }).then((data) => {
            selectData = data.rows||[];
            selectData.unshift({id:"-1",pid:"0",name:'根目录',sort:"0",url:'',level:'0'})
            this.setState({
              treeSelectData : _this.createSelect("0")
            })
        });
            
  }
  onChange = (value) => {
    let obj = {};
    obj.url = '';
    obj.showXingzhi = true;
    for(let i = 0;i<selectData.length;i++){
      if(value.value === selectData[i].id){
        obj.url = selectData[i].url || '';
        obj.showXingzhi = selectData[i].level < 2 ? true : false;
        if(!obj.showXingzhi){obj.show = 2}
        break;
      }
    }
    obj.value = value;
    obj.url = obj.url + '/';
    this.setState(obj);
  }
  onChangeXingzhi = (e) => {
    this.setState({
      show: e.target.value,
    });
  }
  onChangeInput = prop => e => { 
      this.setState({
          [prop]: e.target.value.trim()
      });
  }
  //回车事件
  KeyUpHandle(e){
      if (e.keyCode !== 13) {
          return;
      }
      this.submit();
  }
  //提交
  submit(){
    let _this = this;
    if(!this.state.name || this.state.name.length < 2 || this.state.name.length > 5){
            message.error('请输入正确的名称');
            return;
        }
        if(!this.state.value){
            message.error('请选择所属功能分类');
            return;
        }
        if(!this.state.url){
            message.error('请输入访问地址');
            return;
        }
        this.setState({
            loading: true
        });
        reqwest({
          url: CONFIG.url.lc_url+'Backend/sysFunc/create',
          method: 'post',
          contentType: 'application/x-www-form-urlencoded',
          crossOrigin: true,
          withCredentials: true,
          data: {
            name : this.state.name,
            is_menu : this.state.show,
            is_func : this.state.show,
            sort : this.state.sort,
            url : this.state.url,
            icon : this.state.icon,
            pid : this.state.value.value === '-1' ? 0 : this.state.value.value
          },
          type: 'json',
        }).then((data) => {
            if(data.code === 0){
              message.success(data.msg);
              _this.props.history.go(-1);
            }else{
              message.error(data.msg);
              _this.setState({
                  loading: false
              });    
            }
          
        });
  }
  render() {
    const {loading}=this.state;
    return (
      <div>
        <div className="steps-content">
          <div style={{padding:'24px 30px'}}>
      <Form>
    <FormItem
      {...formItemLayout}
      label="功能名称"
      validateStatus=""
      help=""
    >
      <Input placeholder="请输入功能名称,2-5个字" value={this.state.name} id="name" 
            onKeyUp={this.KeyUpHandle.bind(this)}
            onChange={this.onChangeInput('name')}/>
    </FormItem>
    
    <FormItem
      {...formItemLayout}
      label="所属分类"
      hasFeedback
      validateStatus=""
      help=""
    >
      <TreeSelect
        style={{ width: '100%'}}
        value={this.state.value}
        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        treeData={this.state.treeSelectData}
        placeholder="请选择所属功能分类"
        treeDefaultExpandAll
        labelInValue
        onChange={this.onChange}
      />
    </FormItem>
    <FormItem
      {...formItemLayout}
      label="访问地址"
      hasFeedback
      validateStatus=""
    >
      <Input placeholder="请输入以/开头的URL地址" value={this.state.url} id="url" 
            onKeyUp={this.KeyUpHandle.bind(this)}
            onChange={this.onChangeInput('url')}/>
    </FormItem>

    
    <FormItem
      {...formItemLayout}
      label="菜单显示"
      hasFeedback
      validateStatus=""
       style={{display:(this.state.showXingzhi ? 'block':'none')}}
    >
      <RadioGroup onChange={this.onChangeXingzhi} value={this.state.show}>
        <Radio value={1}>显示</Radio>
        <Radio value={2}>不显示</Radio>
      </RadioGroup>
    </FormItem>
    <FormItem
      {...formItemLayout}
      label="菜单图标"
      validateStatus=""
    >
      <Input placeholder="请输入antd库icon图标样式名" value={this.state.icon} id="icon" 
            onKeyUp={this.KeyUpHandle.bind(this)}
            onChange={this.onChangeInput('icon')}/>
    </FormItem>
    <FormItem
      {...formItemLayout}
      label="排序"
      validateStatus=""
    >
      <Input placeholder="菜单显示顺序为正序排列" id="sort" value={this.state.sort}
            onKeyUp={this.KeyUpHandle.bind(this)}
            onChange={this.onChangeInput('sort')}/>
    </FormItem>

  </Form>

    </div>
        </div>
        <div className="steps-action" style={{textAlign:'left',paddingBottom:'50px'}}>
        <Row gutter={48}>
        <Col className="gutter-row" span={7}></Col>
          <Col className="gutter-row" span={10}>
          <Button type="primary" loading={loading} onClick={this.submit.bind(this)}>{loading ? '' : '提交'}</Button>          
          </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withRouter(CreateHospital)