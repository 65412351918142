import React from "react";
import { Button, message,Form, Input, Row, Col, TreeSelect ,Upload,Icon } from 'antd';
import http from '../../../server';
import { withRouter } from "react-router";
import CONFIG from '../../../config';
const { TextArea } = Input;
const FormItem = Form.Item;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 7 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
};

var selectData=[];
let uploadOpt = {
  accept : 'image/gif, image/jpeg, image/jpg, image/png, image/bmp',
  action: CONFIG.url.lc_url+'Backend/backend/upload',
  listType: 'picture',
  defaultFileList: [],
  disabled : false,//是否禁用
  multiple : false,//多选文件
  withCredentials : true,
  className: 'upload-list-inline'
};
class CreateHospital extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading : false,
      value: undefined,
      name : '',
      icon : '',
      status : 1,
      business_introduction : '',
      sort : 99,
      treeSelectData : [],
      uploadlogo : {
        onChange : this.changeUpload.bind(this,'logo',1),
        onRemove : this.removeUpload.bind(this,'logo',1),
        ...uploadOpt
      },
    };
  }

  //上传图片
  changeUpload = (type,len,e) =>{
    if(e.file.status === 'done'){
      //上完完成
      if(e.file.response.code === 0){
        //上传成功
        this.setState({
          [type] : e.fileList,//e.file.response.data.save_path,
          ['upload'+type] : {
            ...this.state['upload'+type],disabled:(e.fileList.length >= len ? true : false),
            defaultFileList : e.fileList
          }
        });
      }
    }
  }
  //删除图片
  removeUpload = (type,len,e) =>{
    if(e.status === 'removed'){
      let arr = this.state[type].filter(o => o.uid !== e.uid);
      this.setState({
        [type] : arr,//e.file.response.data.save_path,
        ['upload'+type] : {...this.state['upload'+type],disabled:(arr.length >= len ? true : false),defaultFileList:arr}
      })
    }    
  }
  getUploadPath(arr){
    if(!arr.length)return '';
    for(let i = 0;i<arr.length;i++){arr[i] = arr[i].response.data.save_path;}
    return arr.join(',');
  }
  componentDidMount(){    
    this.fetch({mer_id : CONFIG.S.get('mer_id')||''});
  }

createSelect(pid){
  let temp = [];
  for(let i = 0;i<selectData.length;i++){
    if(selectData[i].p_id===pid){
      temp.push({
        title : selectData[i].name,
        sort : selectData[i].sort,
        value : selectData[i].id,
        key : selectData[i].p_id+'-'+selectData[i].id,
        children : [],
        ...selectData[i]
      });
    }
  }
  if(temp.length){
    temp = temp.sort(function(a,b){
      return a.sort > b.sort ? 1 : -1
    });
    for(let j = 0;j<temp.length;j++){
      temp[j].children=this.createSelect(temp[j].id);
    }
  }
  return temp;
}
async fetch(params={}){
    let _this = this;
    await http.post('/Backend/merchant/find',{...params}).then(function(data){
      data = data.data;
      selectData = data.rows||[];
      if(CONFIG.S.get('mer_pid')==="0")selectData.unshift({id:"-1",p_id:"0",name:'根目录',sort:"0",url:'',level:'0'})
      _this.setState({
        treeSelectData : _this.createSelect(CONFIG.S.get('mer_pid')||"0")
      })
    })
  }
  onChange = (value) => {    
    this.setState({ value});
  }
  onChangeInput = prop => e => {    
      this.setState({
          [prop]: e.target.value.trim()
      });
  }
  //回车事件
  KeyUpHandle(e){
      if (e.keyCode !== 13) {
          return;
      }
      this.submit();
  }
  //提交
  submit(){
    let _this = this;
    let logo = this.getUploadPath(this.state.logo);
    if(!this.state.name || this.state.name.length < 2 || this.state.name.length > 40){
        message.error('请输入正确的名称');
        return;
    }
    if(!this.state.value){
        message.error('请选择上级服务商户');
        return;
    }
    if(!logo || logo.length <2 ){
      message.error('请上传logo icon');
      return;
    }
    // this.setState({
    //     loading: true
    // });
    http.post('/Backend/merchant/create',{
        name : this.state.name,
        status : 1,
        business_introduction : this.state.business_introduction,
        p_id : this.state.value.value === '-1' ? 0 : this.state.value.value,
        pid : this.state.value.value === '-1' ? 0 : this.state.value.value,
        logo : logo

      }).then((data) =>{
        data = data.data;
        if(data.code === 0){
          message.success(data.msg);
          _this.props.history.go(-1);
        }else{
          message.error(data.msg);
          _this.setState({
              loading: false
          }); 
        }
      });
  }
  render() {
    const {loading}=this.state;
    return (
      <div>
        <div className="steps-content">
          <div style={{padding:'24px 30px'}}>
      <Form>
    <FormItem
      {...formItemLayout}
      label="商户名称"
      validateStatus=""
      help=""
    >
      <Input placeholder="请输入商户名称,2-40个字" value={this.state.name} id="name" 
            onKeyUp={this.KeyUpHandle.bind(this)} maxLength={40}
            onChange={this.onChangeInput('name')}/>
    </FormItem>
    <FormItem
      {...formItemLayout}
      label="商户ICON"
      validateStatus=""
      help=""
    >
      <Upload {...this.state.uploadlogo}>
        <Button>
          <Icon type="upload" /> 200*200等比率
        </Button>
      </Upload>
    </FormItem>
    <FormItem
      {...formItemLayout}
      label="服务商户"
      hasFeedback
      validateStatus=""
      help=""
    >
      <TreeSelect
        style={{ width: '100%'}}
        value={this.state.value}
        treeData={this.state.treeSelectData}
        placeholder="请选择上级服务商户"
        treeDefaultExpandAll
        labelInValue
        onChange={this.onChange}
      />
    </FormItem>
<FormItem
      {...formItemLayout}
      label="商户简介"
      validateStatus=""
      help=""
    >
      <TextArea placeholder="描述商户(选填)1000字以内" autosize maxLength={1000}  value={this.state.business_introduction} id="business_introduction" 
            onKeyUp={this.KeyUpHandle.bind(this)}
            onChange={this.onChangeInput('business_introduction')}/>
    </FormItem>
  </Form>

    </div>
        </div>
        <div className="steps-action" style={{textAlign:'left',paddingBottom:'50px'}}>
        <Row gutter={48}>
        <Col className="gutter-row" span={7}></Col>
          <Col className="gutter-row" span={10}>
          <Button type="primary" loading={loading} onClick={this.submit.bind(this)}>{loading ? '提交中' : '提交'}</Button>          
          </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withRouter(CreateHospital)